define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["de", {
    "aeConfiguration": {
      "apps": {
        "accommodations": {
          "accommodation": "Unterkunft",
          "accommodationText": "Unterkunft Name",
          "addAccommodation": "Unterkunft hinzufügen",
          "dialog": {
            "title": "Unterkunft Kanal"
          }
        },
        "journal": {
          "dialog": {
            "empty": "Es sind keine Beiträge vorhanden.",
            "error": "Beim Laden der Beiträge ist ein Fehler aufgetreten.",
            "title": "Beitrag wählen"
          }
        },
        "offer": {
          "dialog": {
            "empty": "Es sind keine Angebote vorhanden.",
            "error": "Beim Laden der Angebote ist ein Fehler aufgetreten.",
            "title": "Angebot wählen"
          }
        },
        "survey": {
          "dialog": {
            "empty": "Es sind keine Umfragen vorhanden.",
            "error": "Beim Laden der Umfragen ist ein Fehler aufgetreten.",
            "title": "Umfrage wählen"
          }
        },
        "voucher": {
          "dialog": {
            "empty": "Es sind keine Gutscheine vorhanden.",
            "error": "Beim Laden der Gutscheine ist ein Fehler aufgetreten.",
            "expirationFormats": {
              "end_of_next_year": "Ende des nächsten Jahres",
              "end_of_year": "Ende des Jahres",
              "five_years": "5 Jahre",
              "indefinitely": "Unbegrenzt",
              "one_year": "1 Jahr",
              "three_years": "3 Jahre",
              "three_years_end_of_year": "Ende des dritten Jahres",
              "two_years": "2 Jahre"
            },
            "openVoucherApp": "Neuen Gutschein in A+ GUTSCHEINE erstellen",
            "title": "Gutschein wählen",
            "voucherProperties": {
              "validityPeriod": "Gültigkeit",
              "value": "Wert"
            },
            "voucherTypes": {
              "dynamic": "Benutzerdefiniert",
              "gift": "Incentive-Gutschein",
              "product": "Produktgutschein",
              "service": "Leistungsgutschein",
              "stay": "Übernachtungsgutschein",
              "treatment": "Behandlungsgutschein",
              "value": "Wertgutschein"
            }
          }
        },
        "voucherWidget": {
          "dialog": {
            "empty": "Es sind keine Gutschein Widgets vorhanden.",
            "error": "Beim Laden der Gutschein Widgets ist ein Fehler aufgetreten.",
            "title": "Gutschein Widget wählen"
          }
        }
      },
      "content": {
        "allCategories": "Alle Kategorien",
        "allContents": "Alle Inhalte",
        "expired": "Abgelaufen",
        "selectCategory": "Kategorie wählen",
        "selectContent": "Inhalt wählen"
      },
      "dateRanges": {
        "addDateRange": "Zeitraum hinzufügen",
        "dateRange": "Zeitraum"
      },
      "images": {
        "addImage": "Bild hinzufügen",
        "imageTitle": "Bild {count}"
      },
      "item": {
        "addFile": "Datei hinzufügen",
        "addText": "Text hinzufügen",
        "addUrl": "URL hinzufügen",
        "file": "Datei",
        "helpTexts": {
          "inheritanceDescriptionStyle": "Wenn dieses Symbol aktiviert (blau) ist, werden Daten oder Inhalte mit jenen Ihrer Organisation (Account) synchronisiert. Dadurch können Sie die Daten zentral in Ihrer Organisation für alle Apps verwalten.",
          "inheritanceDescriptionTemplate": "Wenn dieses Symbol aktiviert (blau) ist, werden Daten oder Inhalte mit jenen des Stils synchronisiert. Dadurch können Sie die Daten zentral im Stil für alle Kampagnen und Vorlagen verwalten."
        },
        "language": "Sprache",
        "multiSelectPlaceholder": "Optionen wählen",
        "noLink": "Keine Verlinkung",
        "survey": "Umfrage",
        "surveyLink": "Link zu Umfrage",
        "textDialogTitle": "Text eingeben",
        "type": "Typ",
        "url": "URL",
        "urlDialogTitle": "Link-Konfiguration"
      },
      "validations": {
        "accommodations": {
          "index": {
            "channelCode": "Kanal darf nicht leer sein.",
            "required": "Text darf nicht leer sein."
          },
          "minCount": "Es {minCount, plural, =1 {muss mindestens eine Unterkunft} other {müssen mindestens # Unterkünfte}} hinzugefügt werden."
        },
        "general": {
          "expiredContent": "Dieser Inhalt ist abgelaufen.",
          "index": {
            "required": "Dieser Wert darf nicht leer sein.",
            "textPlaceholder": "Der Text enthält ungültige Platzhalter.",
            "url": "Der Link ist ungültig."
          },
          "maxValue": "Der Wert muss kleiner gleich {maxValue} sein.",
          "minValue": "Der Wert muss größer gleich {minValue} sein.",
          "noContent": "Diese Auswahl hat keine veröffentlichten Inhalte.",
          "placeholder": "Dieser Wert enthält ungültige Platzhalter.",
          "required": "Dieser Wert darf nicht leer sein.",
          "text": {
            "placeholder": "Der Text enthält ungültige Platzhalter.",
            "required": "Der Text darf nicht leer sein."
          },
          "unpublishedContent": "Dieser Inhalt ist nicht veröffentlicht.",
          "url": {
            "placeholder": "Der Link enthält ungültige Platzhalter.",
            "required": "Der Link darf nicht leer sein.",
            "url": "Der Link ist ungültig."
          }
        },
        "images": {
          "index": {
            "required": "Ein Bild muss gesetzt sein.",
            "url": "Der Link ist ungültig."
          },
          "required": "Fügen Sie mindestens ein Bild hinzu."
        },
        "multimedia": {
          "file": "Es darf nur eine Datei ausgewählt werden.",
          "image": "Es darf nur ein Bild ausgewählt werden.",
          "images": "Es dürfen nur Bilder ausgewählt werden.",
          "medium": "Es darf nur ein Bild oder Youtube-Video ausgewählt werden."
        }
      }
    },
    "aiButton": {
      "loadingProgress": "Generiert..."
    },
    "app": {
      "title": "ADDITIVE+ GUTSCHEINE"
    },
    "appNames": {
      "1": {
        "name": "ACCOUNT",
        "presentationName": "ADDITIVE+ ACCOUNT"
      },
      "10": {
        "name": "MARKETING DASHBOARD",
        "presentationName": "ADDITIVE+ MARKETING DASHBOARD"
      },
      "100": {
        "name": "CMS",
        "presentationName": "ADDITIVE+ CMS"
      },
      "11": {
        "name": "ANFRAGEN",
        "presentationName": "ADDITIVE+ ANFRAGEN"
      },
      "12": {
        "name": "CRM",
        "presentationName": "ADDITIVE+ CRM"
      },
      "2": {
        "name": "GUTSCHEINE",
        "presentationName": "ADDITIVE+ GUTSCHEINE"
      },
      "4": {
        "name": "INHALTE",
        "presentationName": "ADDITIVE+ INHALTE"
      },
      "5": {
        "name": "MULTIMEDIA",
        "presentationName": "ADDITIVE+ MULTIMEDIA"
      },
      "50": {
        "name": "MARKETING AUTOMATION",
        "presentationName": "ADDITIVE+ MARKETING AUTOMATION"
      },
      "51": {
        "name": "MARKETING INSIGHTS",
        "presentationName": "ADDITIVE+ MARKETING INSIGHTS"
      },
      "6": {
        "name": "MAILER",
        "presentationName": "ADDITIVE+ MAILER"
      },
      "7": {
        "name": "NEWSLETTER",
        "presentationName": "ADDITIVE+ NEWSLETTER"
      },
      "8": {
        "name": "GÄSTE INTRANET",
        "presentationName": "ADDITIVE+ GÄSTE INTRANET"
      },
      "9": {
        "name": "JOURNAL",
        "presentationName": "ADDITIVE+ JOURNAL"
      }
    },
    "auditLog": {
      "deletedUser": "Gelöschter Benutzer",
      "description": "Hier werden die Aktivitäten aller Benutzer Ihrer Organisation innerhalb der App chronologisch aufgelistet.",
      "empty": "Keine Aktivitäten vorhanden",
      "entries": "Einträge",
      "page": "Seite",
      "time": "{time} Uhr",
      "title": "Aktivitätenprotokoll"
    },
    "awColorsMenu": {
      "accent": "Akzentfarbe",
      "alpha": "Alpha",
      "ambient": "Umgebungsfarbe",
      "custom": "Benutzerdefiniert",
      "dialog": {
        "color": {
          "label": "Farbe"
        },
        "contrastColor": {
          "label": "Kontrastfarbe"
        }
      },
      "main": "Hauptfarbe"
    },
    "awTypographyDialog": {
      "button": {
        "description": "",
        "title": "Buttons"
      },
      "desktop": "Desktop",
      "enableUppercase": "Großbuchstaben aktivieren",
      "fontColor": "Schriftfarbe",
      "fontFamily": "Schriftfamilie",
      "fontOptions": {
        "default": "Standard",
        "sansserif": "Serifenlos",
        "serif": "Serif"
      },
      "fontSize": "Schriftgröße",
      "h1": {
        "description": "",
        "title": "Überschriften"
      },
      "h2": {
        "description": "",
        "title": "Unterüberschriften"
      },
      "h3": {
        "description": "",
        "title": "Weitere Unterüberschriften"
      },
      "label": {
        "description": "",
        "title": "Labels"
      },
      "letterSpacing": "Laufweite",
      "letterSpacingUnit": "em",
      "lineHeight": "Zeilenhöhe",
      "mobile": "Mobil",
      "p": {
        "description": "",
        "title": "Text"
      },
      "small": {
        "description": "",
        "title": "Text (klein)"
      },
      "types": {
        "customFonts": "Benutzerdefinierte Schriften",
        "sansserif": "Serifenlose Schriften",
        "serif": "Serife Schriften"
      },
      "uppercase": "Großbuchstaben"
    },
    "awTypographyList": {
      "item": {
        "corporateDesignTypography": {
          "button": "Button",
          "h1": "Überschrift 1",
          "h2": "Überschrift 2",
          "h3": "Überschrift 3",
          "label": "Label",
          "p": "Paragraph",
          "small": "Small"
        },
        "warning": "Benutzerdefinierte Schriftfamilien werden nicht in der Vorschau angezeigt."
      },
      "properties": {
        "button": {
          "description": "",
          "title": "Buttons"
        },
        "h1": {
          "description": "",
          "title": "Hauptüberschriften"
        },
        "h2": {
          "description": "",
          "title": "Unterüberschriften"
        },
        "h3": {
          "description": "",
          "title": "Weitere Unterüberschriften"
        },
        "label": {
          "description": "",
          "title": "Labels"
        },
        "p": {
          "description": "",
          "title": "Text"
        },
        "small": {
          "description": "",
          "title": "Text (klein)"
        }
      }
    },
    "awTypographySettings": {
      "embedFonts": {
        "description": "Wenn diese Option aktiviert ist, werden alle verwendeten Schriftarten im Widget eingebunden. Dies kann zu längeren Ladezeiten führen.",
        "title": "Schriften einbinden"
      },
      "fontName": {
        "dialog": {
          "bold": "Bold",
          "confirm": "Bestätigen",
          "description": "Hier können die Namen der verschiedenen Schriftarten definiert werden. Die gewählte Bezeichnung muss mit jener der Website übereinstimmen. Falls der Schriftschnitt nicht durch unterschiedliche Bezeichnungen sondern über die CSS-Eigenschaft \"font-weight\" definiert ist, kann dieselbe Bezeichnung für jeden Schriftschnitt verwendet werden.",
          "light": "Light",
          "normal": "Normaler Schriftschnitt",
          "title": "Schriftbezeichung für \"{font}\""
        },
        "title": "Schriftbezeichnung"
      }
    },
    "breadcrumbs": {
      "demo": {
        "views": {
          "routeName": "Views",
          "simple-breadcrumb": {
            "routeName": "simple-breadcrumb"
          }
        }
      },
      "docs": {
        "components": {
          "routeName": "Components",
          "ui-breadcrumbs": {
            "routeName": "UI-Breadcrumbs"
          },
          "ui-button": {
            "routeName": "Buttons"
          },
          "ui-chart": {
            "routeName": "Charts"
          }
        },
        "routeName": "Docs"
      },
      "instance": {
        "widgets": {
          "routeName": "Widgets",
          "templates": {
            "routeName": "Vorlagen",
            "template": {
              "routeName": "Vorlage"
            }
          },
          "widget": {
            "routeName": "Widget"
          }
        }
      }
    },
    "colorsMenu": {
      "accent": "Akzentfarbe",
      "ambient": "Umgebungsfarbe",
      "main": "Hauptfarbe",
      "standard": "Standardfarbe"
    },
    "components": {
      "av-customer-form-modal": {
        "isCompany": "Als Unternehmen kaufen",
        "otherData": "Sonstige Kontaktdaten",
        "properties": {
          "addressCountry": "Land",
          "addressLocality": "Ort",
          "companyName": "Unternehmensbezeichnung",
          "companyRecipientCode": "Empfängerkodex",
          "email": "E-Mail-Adresse *",
          "familyName": "Nachname *",
          "gender": "Geschlecht *",
          "genders": {
            "female": "Weiblich",
            "male": "Männlich",
            "none": "Keine Angabe"
          },
          "givenName": "Vorname *",
          "noCountry": "Keine Angabe",
          "postalCode": "Postleitzahl",
          "streetAddress": "Straße",
          "telephone": "Telefon",
          "vat": "USt-ID (MwSt.Nr.)"
        },
        "title": "Kontaktdaten"
      },
      "av-package-detail-modal": {
        "description": "Geschenkoptionen werden im Gutschein-Widget ausgegeben und können vom Käufer ausgewählt werden.",
        "errors": {
          "inactiveShipping": {
            "description": "{count, plural, =1 {Eine} other {#}} ausgewählte {count, plural, =1 {Versandoption} other {Versandoptionen}} ({inactiveShippings}) {count, plural, =1 {ist} other {sind}} nicht aktiviert oder {count, plural, =1 {existiert} other {existieren}} nicht.",
            "title": "Achtung!"
          }
        },
        "properties": {
          "description": "Beschreibung",
          "name": "Name",
          "price": "Preis",
          "shipping": "Versandoptionen"
        },
        "shipping": {
          "description": "Wählen Sie eine oder mehrere Versandoptionen aus, für die Sie diese Geschenkoption ermöglichen möchten.",
          "title": "Versandoptionen"
        },
        "title": "Geschenkoption"
      },
      "av-package-list": {
        "title": "Geschenkoptionen"
      },
      "av-shipping-detail-modal": {
        "countryPrices": {
          "addPriceVariant": "Preisvariante hinzufügen",
          "description": "Definieren Sie hier die Versandpreise für diese Versandoption pro Land.",
          "title": "Länder & Preise"
        },
        "description": "Versandoptionen werden im Gutschein-Widget ausgegeben und können vom Käufer ausgewählt werden.",
        "properties": {
          "countries": "Länder",
          "description": "Beschreibung",
          "name": "Name",
          "price": "Preis",
          "shipping": "Versandoptionen"
        },
        "title": "Versandoption"
      },
      "avWidgetV1Notification": {
        "buttonText": "Zu den Widgets",
        "defaultText": "Sie nutzen aktuell die alte Version des Gutschein-Widgets. Das Feature 'Incentive-Gutschein' ist nur in Verbindung mit dem neuen Widget möglich, welches von Ihrem Website-Dienstleister eingebaut werden kann. Die notwendigen Codes finden Sie im Menüpunkt 'Widgets & Templates'."
      },
      "content-info": {
        "showLess": "Informationen ausblenden",
        "showMore": "Weitere Informationen einblenden"
      },
      "sync-dialog": {
        "actions": {
          "sendEmail": "Anleitung senden"
        },
        "asa": {
          "description": "Bitte fügen Sie hier die E-Mail Ihres zuständigen ASA-Dienstleisters ein. Nach Klick auf “Anleitung senden” erhält dieser die notwendigen Zugangsdaten sowie die Anleitung für die Einrichtung der Synchronisierung.",
          "title": "ASA Verbindung"
        },
        "credentials": {
          "email": "E-Mail"
        },
        "descriptionAlreadySynced": "<b>Achtung!</b><br>Anleitung wurde bereits versendet. Beim wiederholten Senden der Anleitung wird ein neues Passwort generiert, welches dann für die Einrichtung verwendet werden muss (das vorher generierte Passwort funktioniert in diesem Fall nicht mehr).",
        "hgv": {
          "description": "Bitte fügen Sie hier die E-Mail Ihres zuständigen Dienstleisters bei HGV-Buchungswidget ein. Nach Klick auf “Anleitung senden” erhält dieser die notwendigen Zugangsdaten sowie die Anleitung für die Einrichtung der Synchronisierung.",
          "title": "HGV-Buchungswidget Verbindung"
        }
      }
    },
    "dashboard": {
      "charts": {
        "comparison": {
          "last_year": "zum Vorjahr"
        },
        "labels": {
          "ordered": "Bestellt",
          "ordered_amount": "Bestellter Betrag",
          "paid": "Bezahlt",
          "paid_amount": "Bezahlter Betrag",
          "redeemed": "Eingelöst",
          "redeemed_amount": "Eingelöster Betrag"
        },
        "orderedAmountChart": {
          "description": "Das Diagramm zeigt die Gesamtbeträge aller bestellten, bezahlten sowie eingelösten Gutscheine {year, select, last_12_months{der letzten 12 Monate} other {in {year}}}.",
          "title": "Beträge"
        },
        "ordersChart": {
          "description": "Das Diagramm zeigt die Anzahl aller bestellten, bezahlten sowie eingelösten Gutscheine {year, select, last_12_months{der letzten 12 Monate} other {in {year}}}.",
          "title": "Bestellungen"
        }
      },
      "description": "Die folgenden Kennzahlen geben einen Überblick über Anzahl und Betrag der Gutscheinbestellungen des laufenden Jahres!",
      "keyIndicators": {
        "amountSumMonthly": {
          "description": "Kaufbetrag (Umsatz) Gutscheinbestellungen im aktuellen Monat (inklusive Geschenks- & Versandoptionen)",
          "name": "Kaufbetrag im {month} {year}"
        },
        "amountSumYearly": {
          "description": "Kaufbetrag (Umsatz) Gutscheinbestellungen (inklusive Geschenks- & Versandoptionen)",
          "name": "Kaufbetrag in {period, select, last_12_months{den letzten 12 Monaten} other {{year}}}"
        },
        "ordersCountMonthly": {
          "description": "Anzahl Gutscheinbestellungen im aktuellen Monat (exklusive Storno und Incentive-Gutschein)",
          "name": "Bestellungen im {month} {year}"
        },
        "ordersCountYearly": {
          "description": "Anzahl Gutscheinbestellungen (exklusive Storno und Incentive-Gutschein)",
          "name": "Bestellungen in {period, select, last_12_months{den letzten 12 Monaten} other {{year}}}"
        }
      },
      "lastOrders": {
        "columns": {
          "identifier": "Bestellnummer",
          "name": "Name",
          "orderedAt": "Bestelldatum",
          "state": "Status",
          "voucher": "Gutschein"
        },
        "description": "Die Tabelle zeigt die letzten 10 Bestellungen, die eingegangen sind oder bei denen sich der Status geändert hat.",
        "filter": {
          "empty": "Ihre Filterung ergab keine Treffer.",
          "label": "Filter"
        },
        "popover": "Bestellung anzeigen",
        "title": "Letzte Bestellungen"
      },
      "newOrderDialog": {
        "dynamicVoucherDialog": {
          "properties": {
            "amount": "Wert *",
            "name": "Name *",
            "services": "Konditionen / Leistungen *",
            "validityPeriod": "Gültigkeit *",
            "validityPeriodCustomDate": "Benutzerdefiniertes Gültigkeitsdatum *"
          },
          "title": "Gutschein erstellen"
        },
        "multimedia": {
          "imageWarning": "Es können nur Bilder ausgewählt werden."
        },
        "order": {
          "actionLabels": {
            "customer": "Kontaktinformationen hinzufügen",
            "language": "Sprache wählen",
            "layout": "Layout wählen",
            "note": "Notiz hinzufügen",
            "orderVoucher": "Gutschein bestellen",
            "paymentType": "Zahlungsart wählen",
            "preview": "Vorschau anzeigen",
            "text": "Grußbotschaft hinzufügen",
            "title": "Anrede hinzufügen",
            "value": "Wert hinzufügen"
          },
          "help": {
            "gift": "Wenn diese Option aktiv ist, wird der Gutschein als Geschenk versendet. Der Gutschein wird sofort an die unter \"Kontaktinformationen\" angegebene E-Mail-Adresse versendet."
          },
          "labels": {
            "customer": "Kontaktinformationen",
            "gift": "Incentive-Gutschein",
            "language": "Sprache",
            "layout": "Layout",
            "note": "Notiz",
            "paymentType": "Zahlungsart",
            "preview": "Vorschau",
            "text": "Grußbotschaft",
            "title": "Anrede",
            "value": "Gutscheinwert"
          },
          "placeholders": {
            "note": "Allergie gegen Milchprodukte",
            "paymentType": "Zahlungsart wählen",
            "title": "Lieber Max"
          },
          "warnings": {
            "recommendedLength": "Wert überschreitet die empfohlene Länge von {recommendedLength} Zeichen."
          }
        },
        "orderElementVoucher": {
          "actionLabel": "Gutschein wählen!",
          "discountedPrice": "Rabattierter Preis",
          "label": "Gutschein *",
          "validUntil": "Gültig bis",
          "validity": "Gültigkeit",
          "voucherAmount": "Gutscheinwert"
        },
        "orderTotalAmount": {
          "label": "Summe"
        },
        "orderVoucher": "Gutschein bestellen",
        "paymentTypesError": "Sie müssen zuerst eine der folgenden Zahlungsarten aktivieren: \"Überweisung\" oder \"Zahlung vor Ort\"",
        "productSelectDialog": {
          "actionLabel": "Produkt hinzufügen",
          "errorMessage": "Bitte wählen Sie mindestens ein Produkt aus.",
          "label": "Produkt *",
          "minutes": "{count, plural, =1 {1 Minute} other {# Minuten}}",
          "selectAction": "Produkte auswählen"
        },
        "roomSelectDialog": {
          "actionLabel": "Zimmer wählen",
          "fixedPersonCount": "Der Gutschein ist auf eine fixe Anzahl an Personen beschränkt.",
          "label": "Zimmer *",
          "roomTypes": {
            "apartment": "Appartement",
            "chalet": "Chalet",
            "double": "Doppelzimmer",
            "family": "Familienzimmer",
            "juniorSuite": "Junior Suite",
            "single": "Einzelzimmer",
            "suite": "Suite"
          },
          "selectAction": "Zimmer auswählen"
        },
        "title": "Neue Gutscheinbestellung",
        "treatmentSelectDialog": {
          "actionLabel": "Behandlung hinzufügen",
          "errorMessage": "Bitte wählen Sie mindestens eine Behandlung aus.",
          "label": "Behandlung *",
          "minutes": "{count, plural, =1 {1 Minute} other {# Minuten}}",
          "selectAction": "Behandlungen auswählen"
        },
        "unexpectedErrorDialog": {
          "description": "Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es etwas später erneut.",
          "title": "Unerwarteter Fehler"
        },
        "validationErrorDialog": {
          "description": "Bitte befüllen Sie alle Pflichtfelder.",
          "title": "Formular nicht vollständig"
        },
        "voucherSelectDialog": {
          "amountWithDiscount": "-- {discount} <span class='red line-through'>{amount}</span> ",
          "amountWithIncentive": "-- Kostenlos <span class='red line-through'>{amount}</span> ",
          "amountWithoutDiscount": "-- {amount}",
          "category": "Gutschein Kategorie",
          "empty": "In dieser Sprache gibt es noch keine öffentlichen Gutscheine.",
          "emptyCategory": "Für diese Kategorie gibt es noch keine öffentlichen Gutscheine.",
          "noVouchersError": "Sie haben noch keine öffentlichen Gutscheine erstellt.",
          "title": "Gutschein wählen"
        }
      },
      "periodSelection": {
        "label": "Betrachtungszeitraum",
        "last_12_months": "Letzte 12 Monate"
      },
      "salutations": {
        "afternoon": "Guten Nachmittag",
        "evening": "Guten Abend",
        "morning": "Guten Morgen"
      },
      "title": "Übersicht"
    },
    "dialogService": {
      "conflictError": {
        "dependencies": {
          "contentTypes": {
            "appIntegrations": "ADDITIVE+ APPS Integrationen",
            "campaigns": "Kampagnen",
            "comments": "Kommentare",
            "landingPages": "Landingpages",
            "leadIntegrations": "Lead-Integrations",
            "pmsIntegrations": "Hotel Software Integrationen",
            "posts": "Beiträge",
            "settings": "Einstellungen",
            "surveys": "Umfragen",
            "thirdPartyTokens": "ADDITIVE+ APPS Schnittstellen",
            "usps": "USPs",
            "widgets": "Widgets"
          },
          "headers": {
            "app": "App",
            "content": "Inhalt",
            "contentType": "Inhaltstyp"
          }
        },
        "description": "Das Objekt kann nicht gelöscht werden, da es von anderen Inhalten verwendet wird. Lösen Sie die Abhängigkeiten bevor Sie das Objekt löschen.",
        "title": "Das Objekt ist in Verwendung"
      },
      "discardChanges": {
        "continueEditing": "Weiterarbeiten",
        "discardAction": "Verwerfen",
        "message": "Sollen ungespeicherte Änderungen verworfen werden?",
        "title": "Achtung!"
      },
      "error": {
        "message": "Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut!",
        "title": "Etwas ist schief gelaufen"
      },
      "noPermission": {
        "message": "Sie haben leider keine Berechtigung um diese Aktion durchzuführen. Wenden Sie sich an Ihren Administrator.",
        "title": "Fehlende Berechtigung"
      }
    },
    "errors": {
      "amountFormat": "Ungültiges Format",
      "beforeDate": "Das Gültigkeitsdatum muss in der Zukunft liegen.",
      "between": "Muss zwischen {min} und {max} liegen",
      "date": "Wert ist kein gültiges Datum",
      "desktopOnly": {
        "template": "Die Bearbeitung und die Personalisierung der Gutscheinvorlage ist auf mobilen Geräten nicht verfügbar.",
        "title": "Achtung!",
        "widget": "Die Bearbeitung und die Personalisierung des Widgets ist auf mobilen Geräten nicht verfügbar."
      },
      "discountPeriod": "Enddatum muss nach dem Startdatum liegen",
      "discountedAmount": "Wert muss kleiner als der Gutscheinwert sein",
      "email": "Wert ist keine gültige E-Mail-Adresse",
      "greaterZero": "Wert muss größer 0 sein",
      "images": "Es dürfen höchstens 10 Bilder hinzugefügt werden.",
      "invalidDateRange": "Ungültiger Zeitraum",
      "invalidRedeemCode": "Ungültiger Gutscheincode",
      "invalidUrl": "Ungültige URL",
      "invalidWidgetName": "Der Widget Name enthält ein ungültiges Zeichen.",
      "maxAmount": "Darf Gutscheinwert nicht unterschreiten",
      "maxLength": "Wert darf eine Länge von {maxLength} Zeichen nicht überschreiten",
      "minAmount": "Darf Gutscheinwert nicht überschreiten",
      "mustStartWithLetter": "Der Widget Name muss mit einem Buchstaben beginnen.",
      "number": "Wert ist keine gültige Zahl",
      "productCategories": "Sie müssen mind. eine Produktkategorie auswählen.",
      "required": "Wert darf nicht leer sein",
      "roomTypes": "Sie müssen mind. einen Zimmertyp auswählen.",
      "treatmentCategories": "Sie müssen mind. eine Behandlungskategorie auswählen.",
      "unlockCode": {
        "format": "Es sind nur Großbuchstaben, Zahlen und Bindestriche \" - \" erlaubt",
        "gift": "Beim Incentive-Gutschein muss dies aktiviert sein.",
        "length": "Freischaltcode muss zwischen {min} und {max} Zeichen lang sein",
        "prefix": "Es sind nur Großbuchstaben (keine Umlaute) und maximal 4 Zeichen erlaubt",
        "unique": "Dieser Freischaltcode ist bereits in Verwendung."
      }
    },
    "faq": {
      "settings": {
        "inheritedSettings": {
          "answer": "Bestimmte Einstellungen können vom ADDITIVE+ ACCOUNT \"geerbt\" werden, wie z.B. die E-Mail-Adresse oder die Telefonnummer. Wenn das DNA-Symbol aktiviert ist, wird die Vererbung aktiviert und automatisch der Wert aus dem ADDITIVE+ ACCOUNT verwendet. Sollten Sie jedoch bei einem bestimmten Feld für ADDITIVE+ GUTSCHEINE einen eigenen Wert festlegen, z.B. eine andere E-Mail-Adresse, so können Sie das DNA-Symbol deaktivieren und einen neuen Wert setzen.",
          "question": "Was bedeutet das DNA-Symbol<img class=\"dib\" style=\"vertical-align: middle;\" width=\"19\" height=\"19\" src=\"/images/dna.svg\"/>?"
        },
        "paymentTypesAmount": {
          "answer": "Die Anzahl der aktiven Zahlungsmethoden ist limitiert. Je nach aktuell verwendeter Lizenz variiert diese maximale Anzahl. Falls Sie noch weitere Zahlungsmethoden benötigen, können Sie ADDITIVE+ GUTSCHEINE im ADDITIVE+ ACCOUNT upgraden.",
          "question": "Warum kann ich keine Zahlungsmethode hinzufügen?"
        }
      },
      "title": "Häufig gestellte Fragen",
      "voucher": {
        "createVoucher": {
          "answer": "Klicke auf den Button mit dem \"+\" am unteren rechten Bildschirmrand. Nun öffnet sich ein Dialog über welchen Sie einen der unterschiedlichen Gutscheintypen erstellen können.",
          "question": "Wie erstelle ich einen Gutschein?"
        },
        "publishVoucher": {
          "answer": "Damit ein Gutschein im Widget erscheint, muss dieser veröffentlicht werden. Klicken Sie hierfür auf einen Gutschein in der Gutscheinliste und klicken Sie anschließend auf das Symbol <img class=\"dib\" style=\"vertical-align: middle;\" width=\"19\" height=\"19\" src=\"/images/publish.svg\"/> am oberen rechten Bildschirmrand. Im sich dann öffnenden Dialogfenster können Sie die Sprachen auswählen, in welchen der Gutschein veröffentlicht werden soll.",
          "question": "Warum wird der Gutschein nicht im Widget angezeigt?"
        },
        "voucherInhouse": {
          "answer": "Im Menüpunkt \"Übersicht\" können Sie eine neue Gutscheinbestellung durchführen, indem Sie dort auf den Button mit dem \"+\" am unteren rechten Bildschirmrand klicken. Dabei öffnet sich ein Dialogfenster, über welchen Sie eine Bestellung tätigen können.",
          "question": "Ich möchte für einen Kunden einen persönlichen Gutschein erstellen. Wie kann ich das machen?"
        },
        "voucherSort": {
          "answer": "Die Gutscheine werden nach zwei Kritieren sortiert: dem Erstellungsdatum und der Anzahl der Sterne. Je mehr Sterne ein Gutschein besitzt, desto weiter vorne wird dieser angezeigt. Wollen Sie also einen Gutschein besonders hervorheben, so können Sie dies mit der Erhöhung der Anzahl der Sterne erreichen.",
          "question": "Wie kann ich meine Gutscheine sortieren?"
        },
        "voucherTemplate": {
          "answer": "Unter dem Menüpunkt \"Widgets & Vorlagen\" können Sie auf den Reiter \"Gutscheinvorlagen\" wechseln. Dort werden die unterschiedlichen Vorlagen angezeigt. Per Klick auf eine der Vorlagen können Sie diese ansehen und bearbeiten. Wenn Sie eine passende Vorlage gefunden haben, können Sie diese als Standard festlegen.",
          "question": "Wo kann ich die Gutscheinvorlage anpassen?"
        },
        "voucherTypes": {
          "answer": "Es gibt insgesamt 4 Gutscheintypen:<ul><li class='pt1'><b>Wertgutscheine:</b> Wertgutscheine stellen die klassischen Gutscheine dar und haben einen bestimmten Wert. Bei der Erstellung eines Wertgutscheins kann der minimale und maximale Wert des Gutscheins festgelegt werden.</li><li class='pt1'><b>Leistungsgutscheine:</b> Mit Leistungsgutscheine lassen sich bestimmte Produkte oder Dienstleistungen als Gutschein darstellen.</li><li class='pt1'><b>Behandlungsgutscheine:</b> Dieser Gutscheintyp ist nur ab der Lizenz \"Professional\" verfügbar. Mit diesem Gutschein lassen sich Behandlungen als Gutschein verschenken. Die Behandlungen werden dabei in ADDITIVE+ INHALTE eingepflegt.</li><li class='pt1'><b>Aufenthaltsgutscheine:</b> Dieser Gutscheintyp ist nur ab der Lizenz \"Professional\" verfügbar. Mit dem Aufenthaltsgutschein lassen sich Nächtigungen für eine beliebige Anzahl an Personen in einem beliebigen Zimmer verschenken. Die Zimmer werden dabei in ADDITIVE+ INHALTE verwaltet.</li></ul>",
          "question": "Welche Arten von Gutscheinen gibt es?"
        },
        "voucherUnlock": {
          "answer": "Mit dem Freischaltcode haben Sie die Möglichkeit einen Gutschein zu verstecken. Wenn diese Funktion aktiviert ist, scheint dieser Gutschein nicht im Widget auf. Er erscheint, sobald der definierte Freischaltcode in der dafür vorgesehenen Schaltfläche vom Nutzer eingegeben wird.",
          "question": "Was ist ein Freischaltcode?"
        },
        "voucherWidget": {
          "answer": "Unter dem Menüpunkt \"Widgets & Vorlagen\" haben wir bereits ein Widget für Sie erstellt. Dieses Widget können Sie grafisch nach Belieben anpassen und anschließend auf Ihrer Website integrieren. Hierfür wird Ihnen ein HTML-Code zur Verfügung gestellt, welchen Sie in die gewünschte Website einbauen können. Grafische Anpassungen werden vom Widget auch nach dem Einbau berücksichtigt.",
          "question": "Wie kann ich die Gutscheine in meine Website integrieren?"
        }
      }
    },
    "font": {
      "sansSerif": "Serifenlos",
      "serif": "Serifen",
      "standard": "Standard"
    },
    "global": {
      "actions": {
        "activate": "Aktivieren",
        "addImage": "Bild hinzufügen",
        "code": "Code",
        "deactivate": "Deaktivieren",
        "delete": "Löschen",
        "download": "Herunterladen",
        "edit": "Bearbeiten",
        "help": "Hilfe",
        "logout": "Abmelden",
        "persist": "Übernehmen",
        "preview": "Vorschau",
        "publish": "Veröffentlichen",
        "reactivate": "Reaktivieren",
        "rename": "Umbenennen",
        "retry": "Erneut versuchen",
        "save": "Speichern",
        "select": "Auswählen",
        "show": "Anzeigen"
      },
      "all": "Alle",
      "delete": {
        "description": "Das Element wird dadurch unwiderruflich gelöscht und kann nicht mehr wiederhergestellt werden.<br/>Soll <b>\"{name}\"</b> wirklich gelöscht werden?",
        "title": "Achtung!"
      },
      "deleteCategory": {
        "description": "Die Kategorie wird dadurch unwiderruflich gelöscht und kann nicht mehr wiederhergestellt werden.<br/>Soll <b>{name}</b> wirklich gelöscht werden?",
        "title": "Achtung!"
      },
      "deleteWidget": {
        "conflictError": {
          "description": "Das Widget kann nicht gelöscht werden, da es von anderen Inhalten verwendet wird. Lösen Sie die Abhängigkeiten bevor Sie das Widget löschen.",
          "title": "Das Widget ist in Verwendung."
        },
        "description": "Das Widget wird dadurch unwiderruflich gelöscht und kann nicht mehr wiederhergestellt werden. Falls das Widget noch auf Ihrer Website eingebunden ist wird es nicht mehr funktionieren.",
        "title": "Achtung!"
      },
      "discardChanges": {
        "action": "Verwerfen",
        "description": "Sollen ungespeicherte Änderungen verworfen werden?",
        "title": "Achtung!"
      },
      "error": "Fehler",
      "errors": {
        "lower_than_min_stays": {
          "message": "Die Gutscheinbestellung kann nicht erstellt werden, da die gewählten Übernachtungen die Mindestaufenthaltsdauer des Zimmers unterschreiten.",
          "title": "Achtung!"
        },
        "public_voucher_left": {
          "message": "Diese Kategorie kann nicht gelöscht werden, da sie öffentliche Gutscheine enthält.",
          "title": "Achtung!"
        },
        "publish": {
          "category_id": {
            "missing_translation": "Es fehlt die entsprechende Übersetzung für diese Kategorie."
          },
          "discounted_amount": {
            "validation": {
              "absent_if": "\"Rabattierter Preis\" darf nicht gesetzt sein ohne aktivierter Rabattierung."
            }
          },
          "has_discount_period": {
            "false_if_not_discounted": "\"Rabattierungszeitraum\" darf nicht gesetzt sein ohne aktivierter Rabattierung."
          },
          "product_categories": {
            "not_exists": "Ein oder mehrere Produkte existieren nicht in dieser Sprache."
          },
          "room_types": {
            "not_exists": "Ein oder mehrere Zimmer existieren nicht in dieser Sprache."
          },
          "services": {
            "validation": {
              "required": "Es fehlt die Leistungsbeschreibung in dieser Sprache.",
              "string": "Es fehlt die Leistungsbeschreibung in dieser Sprache."
            }
          },
          "treatment_categories": {
            "not_exists": "Eine oder mehrere Behandlungen existieren nicht in dieser Sprache."
          }
        },
        "required": "Dieses Feld darf nicht leer sein.",
        "url": "Wert muss eine gültige URL sein."
      },
      "multimedia": {
        "imageWarning": "Es können nur Bilder ausgewählt werden."
      },
      "paymentTypes": {
        "bank_transfer": "Überweisung",
        "cash": "Zahlung vor Ort",
        "concardis": "Concardis",
        "gastro_pool": "gastropool (alte Version)",
        "gastro_pool_v3": "gastropool",
        "hobex": "hobex",
        "hogast": "hogast (alte Version)",
        "hogast_v3": "hogast",
        "klarna": "Klarna",
        "nexi": "Nexi",
        "online": "Zahlung über Website",
        "oppwa": "hobex",
        "outstanding": "Zahlung ausständig",
        "paypal": "PayPal",
        "sia_vpos": "SIA VPOS",
        "six_payments": "SIX Payment Services",
        "stripe": "Stripe",
        "vpos": "Virtual-POS"
      },
      "toasts": {
        "update": {
          "actionLabel": "Neu laden",
          "description": "Ungespeicherte Änderungen gehen verloren.",
          "title": "Update verfügbar"
        }
      }
    },
    "help": {
      "vouchers": {
        "sort": "Gutscheine mit einer hohen Sternebewertung werden im Widget als erstes aufgelistet.<br>Bei der selben Anzahl an Sternen wird jener Gutschein, welcher zuletzt bearbeitet wurde, als erstes angezeigt."
      }
    },
    "languageSelect": {
      "add": "Sprache hinzufügen",
      "ar": "Arabisch",
      "de": "Deutsch",
      "en": "Englisch",
      "fr": "Französisch",
      "it": "Italienisch",
      "nl": "Niederländisch",
      "pl": "Polnisch",
      "remove": "Sprache entfernen",
      "removeDescription": "Soll die Sprache {language} wirklich entfernt werden?",
      "ru": "Russisch"
    },
    "mmAttributesDialog": {
      "alt": "Alt-Attribut",
      "dialogTitle": "Bild Attribute",
      "helpText": "Der Title-Tag eines Bildes ist der Text, der angezeigt wird, wenn man mit dem Mauszeiger auf dem Bild stehenbleibt. Das Alt-Attribut wird von Suchmaschinen verwendet um den Inhalt des Bildes zuzuordnen oder kann auch angezeigt werden, falls das Bild nicht geladen werden kann.",
      "save": "Speichern",
      "title": "Titel"
    },
    "mmBulkDownload": {
      "confirm": {
        "description": "Wollen Sie diesen Ordner herunterladen? Der Download Link wird Ihnen per E-mail zugesendet.",
        "title": "Ordner herunterladen"
      },
      "download": "Herunterladen",
      "error": {
        "description": "Der Download des ausgewählten Mediums wird nicht unterstützt.",
        "title": "Achtung"
      },
      "success": {
        "description": "Der Download Link wird Ihnen per E-mail zugesendet.",
        "title": "Download erfolgreich gestartet"
      }
    },
    "mmCropperView": {
      "save": "Speichern"
    },
    "mmExternalSearch": {
      "duration": {
        "hours": "{count, plural, =1 {# Stunde} other {# Stunden}}",
        "minutes": "{count, plural, =1 {# Minute} other {# Minuten}}",
        "seconds": "{count, plural, =1 {# Sekunde} other {# Sekunden}}"
      },
      "insertSearchParam": "Geben Sie einen Suchbegriff ein",
      "noResults": "Keine Suchergebnisse gefunden",
      "provider": {
        "unsplash": {
          "count": "{count, plural, =1 {1 Bild} other {# Bilder}}",
          "description": "Geben Sie hier einen Suchbegriff ein, um nach Unsplash Bildern zu suchen.",
          "name": "Unsplash",
          "save": "Bilder hinzufügen",
          "title": "Unsplash Bild hinzufügen"
        },
        "youtube": {
          "count": "{count, plural, =1 {1 Video} other {# Videos}}",
          "description": "Geben Sie hier einen Suchbegriff ein, um das gewünschte YouTube Video hinzuzufügen oder geben Sie direkt die jeweilige URL ein.",
          "name": "YouTube",
          "save": "Video hinzufügen",
          "title": "Youtube-Video hinzufügen"
        }
      }
    },
    "mmImageTransforms": {
      "brightness": "Helligkeit",
      "contrast": "Kontrast",
      "editDialog": {
        "attributes": {
          "description": "Beschreibungs- und Alternativtext des Bildes festlegen",
          "title": "Bild Attribute setzen"
        },
        "imageTransform": {
          "description": "Damit können Sie das Bild bearbeiten",
          "title": "Bild bearbeiten"
        }
      },
      "imageLoadError": "Beim Laden des Bildes ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.",
      "saturation": "Sättigung",
      "setFocusPoint": "Fokus-Point setzen",
      "title": "Bild bearbeiten"
    },
    "mmMediumItem": {
      "attribution": {
        "unsplash": "Photo by &nbsp;<a target=\"_blank\" rel=\"noopener\" href=\"{authorUrl}\" class=\"white\">{author}</a>&nbsp; on &nbsp;<a target=\"_blank\" rel=\"noopener\" href=\"{url}\" class=\"white\">Unsplash</a>",
        "youtube": "{title} on <u>Youtube</u>"
      },
      "download": "Herunterladen",
      "error": "Die Datei konnte nicht geladen werden.",
      "more": "Mehr"
    },
    "mmResourceGallery": {
      "isImageAddable": "Bild hinzufügen",
      "isImageOrVideoAddable": "Bild oder Video hinzufügen",
      "isVideoAddable": "Video hinzufügen"
    },
    "multimediaEngine": {
      "actions": {
        "add": "Hinzufügen",
        "bulkDelete": "Löschen",
        "bulkDownload": "Herunterladen",
        "delete": "Löschen",
        "move": "Hierher verschieben",
        "remove": "Entfernen",
        "rename": "Umbenennen",
        "retry": "Nochmal versuchen",
        "save": "Speichern"
      },
      "dialogs": {
        "confirmDeleteFolder": {
          "description": "Sind Sie sicher, dass Sie diesen Ordner und dessen Inhalt unwiderruflich löschen wollen?",
          "title": "Ordner löschen"
        },
        "confirmDownloadFolder": {
          "description": "Wollen Sie diesen Ordner herunterladen? Der Download Link wird Ihnen per E-mail zugesendet.",
          "title": "Ordner herunterladen"
        },
        "confirmRemoveFolder": {
          "description": "Sind Sie sicher, dass Sie diesen geteilten Ordner und dessen Inhalt entfernen wollen?",
          "title": "Geteilten Ordner entfernen"
        },
        "deleteFolderIncomplete": {
          "description": "Der Ordner konnte nicht vollständig gelöscht werden, da einige Dateien aktuell verwendet werden. Insgesamt {count, plural, =1 {wurde # Datei} other {wurden # Dateien}} gelöscht.",
          "title": "Ordner konnte nicht gelöscht werden"
        },
        "removeFolderError": {
          "description": "Der geteilte Ordner konnte nicht entfernt werden, da einige Dateien aktuell verwendet werden.",
          "title": "Ordner konnte nicht entfernt werden"
        },
        "youtubeDownloadError": {
          "description": "Der Download des ausgewählten Mediums wird nicht unterstützt.",
          "title": "Achtung"
        }
      },
      "errors": {
        "required": "Wert darf nicht leer sein"
      },
      "folderTree": {
        "root": "Multimedia"
      },
      "folderView": {
        "bulkDelete": {
          "confirmDialog": {
            "description": "Sind Sie sicher, dass Sie {count, plural, =1 {dieses Medium} other {diese Medien}} unwiderruflich löschen wollen?",
            "title": "{count, plural, =1 {Medium} other {Medien}} löschen"
          },
          "conflict": {
            "description": "Ein oder mehrere Medien können nicht gelöscht werden, da sie zurzeit in Inhalten verwendet werden. Um diese Medien löschen zu können müssen Sie diese zuerst von allen Inhalten entfernen.",
            "title": "Nicht alle Medien sind löschbar"
          },
          "disabled": "Geteilte Ordner und Dateien können nicht gelöscht werden.",
          "error": {
            "description": "Die ausgewählten Medien können nicht gelöscht werden, da sie zurzeit in Inhalten verwendet werden. Um diese Medien löschen zu können müssen Sie diese zuerst von allen Inhalten entfernen.",
            "title": "Medien sind nicht löschbar"
          }
        },
        "bulkDownload": {
          "confirmDialog": {
            "description": "Sind Sie sicher, dass Sie {count, plural, =1 {dieses Medium} other {diese Medien}} herunterladen wollen? Der Download Link wird Ihnen per E-mail zugesendet.",
            "title": "{count, plural, =1 {Medium} other {Medien}} herunterladen"
          },
          "disabled": "Geteilte Ordner und Dateien können nicht heruntergeladen werden."
        },
        "bulkMove": {
          "disabledParentFolder": "Ordner können nicht in sich selbst verschoben werden.",
          "disabledSharedFolder": "Innerhalb geteilter Ordner können Ordner und Dateien nicht verschoben werden.",
          "disabledSharedSelection": "Geteilte Ordner und Dateien können nicht verschoben werden."
        },
        "delete": {
          "confirmDeleteMedium": {
            "description": "Sind Sie sicher, dass Sie dieses Medium unwiderruflich löschen wollen?",
            "title": "Medium löschen"
          },
          "conflict": {
            "description": "Das Medium kann nicht gelöscht werden, da es zurzeit in Inhalten verwendet wird. Um dieses Medium löschen zu können müssen Sie es zuerst von allen Inhalten entfernen.",
            "title": "Medium ist in Verwendung"
          }
        },
        "folder": "Ordner",
        "media": "Dateien",
        "new": {
          "addSharedFolder": {
            "title": "Geteilten Ordner hinzufügen"
          },
          "externalSearch": {
            "title": "Externe Medien"
          },
          "folder": {
            "title": "Ordner erstellen"
          },
          "title": "Hinzufügen",
          "upload": {
            "title": "Datei hochladen"
          }
        },
        "sharedFolder": "Geteilte Ordner",
        "sharedMedia": "Geteilte Dateien",
        "uploading": {
          "description": "Während ein Hochladevorgang läuft kann nichts weiteres hochgeladen werden.",
          "title": "Hochladevorgang läuft."
        }
      },
      "mmAddSharedFolderDialog": {
        "errors": {
          "add": "Beim Hinzufügen der geteilten Ordner ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
          "fetch": "Beim Laden der geteilten Ordner ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
        },
        "title": "Geteilten Ordner hinzufügen"
      },
      "mmFileUpload": {
        "error": {
          "title": "Fehler beim Hochladen"
        },
        "maxConcurrentFilesUploaded": {
          "description": "Sie können nur maximal {count} Dateien in einem Hochladevorgang hochladen. Sie können die Restlichen nach dem der aktuelle Hochladevorgang beendet ist hochladen.",
          "title": "Limit für gleichzeitiges Hochladen erreicht"
        },
        "maxSizeDialog": {
          "description": "Eine oder mehrere Dateien welche sie hochladen möchten überschreiten die zulässige Maximalgröße von 10MB, diese werden nicht hochgeladen.",
          "title": "Überschreitung der Maximalgröße"
        }
      },
      "mmFileUploadProgress": {
        "aborted": {
          "title": "Hochladen abgebrochen"
        },
        "errorDialog": {
          "description": "{failed} von {count} konnten nicht hochgeladen werden, da sie entweder nicht unterstützt werden oder ein unerwarteter Fehler aufgetreten ist.",
          "title": "Uploadfehler"
        },
        "progress": {
          "description": "Dateien werden hochgeladen",
          "title": "Hochladen"
        },
        "success": {
          "title": "Hochladen erfolgreich"
        }
      },
      "mmFilter": {
        "mr": "Größer als",
        "noMatches": "Ihre Filterung ergab keine Treffer",
        "searchPlaceholder": "Suchen Sie z.B. nach \"Wellness\"",
        "type": {
          "title": "Dateityp",
          "types": {
            "file": "Datei",
            "image": "Bild",
            "video": "Video"
          }
        }
      },
      "mmFolderCreateEdit": {
        "name": "Name",
        "title": {
          "folder": {
            "create": "Ordner erstellen",
            "edit": "Ordner umbenennen"
          },
          "sharedFolder": {
            "create": "Geteilten Ordner erstellen",
            "edit": "Geteilten Ordner umbenennen"
          }
        }
      },
      "toasts": {
        "bulkDownloadSuccess": {
          "description": "Der Download Link wird Ihnen per E-mail zugesendet",
          "title": "Download erfolgreich gestartet"
        },
        "folderDeleteSuccess": {
          "description": "Der Ordner wurde erfolgreich gelöscht",
          "title": "Ordner gelöscht"
        },
        "success": {
          "title": "Aktion erfolgreich durchgeführt"
        }
      },
      "warnings": {
        "maxCount": "Es {count, plural, =1 {darf maximal # Element} other {dürfen maximal # Elemente}} ausgewählt werden.",
        "recommendedSize": {
          "action": "Trotzdem hinzufügen",
          "message": "In Ihrer Auswahl befinden sich Dateien, welche nicht der empfohlenen Mindestgröße entsprechen.",
          "title": "Achtung"
        }
      }
    },
    "onboarding": {
      "1": {
        "description": "Geben Sie mit ADDITIVE+ GUTSCHEINE Ihren Kunden auf einfache Art und Weise noch mehr Möglichkeiten, Ihre Produkte und Leistungen zu kaufen und zu schenken.",
        "title": "Bereit neues Umsatzpotential auszuschöpfen?"
      },
      "2": {
        "description": "Eine gute Integration und Verlinkung des Gutscheinsystems in Ihrer Website steigert die Sichtbarkeit und somit die Anzahl an Verkäufen.",
        "title": "Automatischer Verkauf über Ihre Website"
      },
      "3": {
        "description": "Verkaufen Sie Gutscheine direkt an Ihrem „Point of Sale“ an der Rezeption, im Restaurant oder an der Kasse. Die Nutzung bestehender Vorlagen macht dabei die Abwicklung effizient und einfach.",
        "title": "Vor-Ort-Verkauf"
      },
      "4": {
        "description": "Kostenlose oder rabattierte Gutscheine sowie individuelle Gutscheine für bestimmte Gäste- und Kundengruppen sind optimale Werkzeuge für gelungenes Marketing mit echter Verkaufsförderung.",
        "title": "Marketingpotenzial nutzen"
      },
      "actions": {
        "back": "Zurück",
        "go": "Jetzt loslegen",
        "next": "Weiter",
        "skip": "Überspringen"
      }
    },
    "orders": {
      "cancelOrder": {
        "action": "Stornieren",
        "description": "Soll <span class=\"font-medium\">\"{name}\"</span> wirklich storniert werden?",
        "title": "Achtung!"
      },
      "customer": {
        "address": "Adresse",
        "buyer": "Käufer",
        "company": {
          "companyRecipientCode": "Empfängerkodex",
          "name": "Unternehmen",
          "vatId": "USt-ID (MwSt.Nr.)"
        },
        "email": "E-Mail-Adresse",
        "gift": "Geschenkoption",
        "shipping": {
          "address": "Versandadresse",
          "name": "Versandoption",
          "title": "Versand- und Geschenkoptionen"
        },
        "telephone": "Telefon",
        "title": "Kontaktinformationen"
      },
      "deleteOrder": {
        "description": "Die Bestellung und alle dazugehörigen Daten werden dadurch unwiderruflich gelöscht und können nicht mehr wiederhergestellt werden.<br>Soll <span class=\"font-medium\">\"{name}\"</span> wirklich gelöscht werden?",
        "title": "Achtung!"
      },
      "description": "Hier werden die Bestellungen chronologisch absteigend aufgelistet.",
      "export": {
        "close": "Schließen",
        "content": "Der Download der Bestellungen wird vorbereitet.<br/>Die CSV-Datei mit der Bestellungsliste wird in Kürze an die E-Mail-Adresse <b>{email}</b> gesendet.",
        "title": "Download wird vorbereitet"
      },
      "order": {
        "actions": {
          "canceled": "Stornieren",
          "confirm_order": "Bestätigung erneut senden",
          "confirm_payment": "Gutschein erneut senden",
          "downloadAsPDF": "PDF herunterladen",
          "extendExpiration": "Gutschein verlängern",
          "issued": "Gutschein generieren",
          "paid": "Als bezahlt markieren",
          "redeemVoucher": "Gutschein einlösen",
          "remind_payment": "An Zahlung erinnern"
        },
        "detailNavbar": {
          "dialogTitle": "Notiz für diese Bestellung hinterlegen",
          "history": {
            "state": {
              "canceled": "Gutschein wurde storniert",
              "expired": "Gutschein ist verfallen",
              "gift": "Incentive-Gutschein wurde versandt",
              "issued": "Gutschein wurde ausgestellt",
              "minutes": "{count, plural, =1 {1 Minute} other {# Minuten}}",
              "ordered": "Gutschein wurde bestellt",
              "paid": "Gutschein wurde bezahlt",
              "partial_redeemed": "{amount, number, EUR} {amount, plural, =1 {wurde} other {wurden}} {redeemedBy, select, hgv { über HGV-Buchungswidget eingelöst} asa { über ASA HOTEL eingelöst } other {eingelöst}}",
              "redeemed": "Gutschein wurde {redeemedBy, select, hgv { über HGV-Buchungswidget eingelöst} asa { über ASA HOTEL eingelöst } other {eingelöst}}"
            },
            "transaction": {
              "bankTransfer": "Gutschein wird mit Überweisung bezahlt",
              "canceled": "Am {date}, um {time} Uhr rückgängig gemacht",
              "false": "Bezahlung durch {paymentType} ist fehlgeschlagen",
              "reactivated": "{user} hat die Bestellung am {date}, um {time} Uhr reaktiviert.",
              "true": "Bezahlung durch {paymentType} war erfolgreich"
            }
          },
          "noNote": "Keine Notiz vorhanden",
          "note": "Notiz",
          "orderedAt": "Eingegangen am"
        },
        "expirationDialog": {
          "action": "verlängern",
          "label": "Verfallsdatum",
          "paymentNotSupported": "Die von Ihnen angegebene Zahlungsmethode wird nicht unterstützt.",
          "placeholder": "Wählen Sie eine Zahlungsmethode aus",
          "title": "Gültigkeit verlängern"
        },
        "issuedDialog": {
          "action": "Gutschein generieren",
          "message": "Der Gutschein wurde noch nicht bezahlt.<br>Wirklich fortfahren und den Gutschein ohne vorherige Bezahlung generieren?",
          "title": "ACHTUNG!"
        },
        "layouts": {
          "landscape": "Querformat",
          "portrait": "Hochformat"
        },
        "paymentDialog": {
          "action": "Als bezahlt markieren",
          "label": "Zahlungsmethode",
          "paymentNotSupported": "Die von Ihnen angegebene Zahlungsmethode wird nicht unterstützt.",
          "placeholder": "Wählen Sie eine Zahlungsmethode aus",
          "title": "Gutschein als bezahlt markieren"
        },
        "reactivateDialog": {
          "action": "Bestellung reaktivieren",
          "description": "Diese Aktion reaktiviert eine bereits als eingelöst markierte oder stornierte Bestellung.",
          "title": "Bestellung reaktivieren"
        },
        "redeemDialog": {
          "action": "Jetzt einlösen",
          "amount": {
            "label": "Betrag *"
          },
          "comment": {
            "label": "Kommentar",
            "placeholder": "Fügen Sie der Aktion einen Kommentar bei."
          },
          "infoMessage": "Beachten Sie die Groß- und Kleinschreibung",
          "redeemCode": {
            "label": "Gutscheincode *",
            "placeholder": "Gutscheincode"
          },
          "redeemPartially": "Teilweise einlösen",
          "title": "Gutschein einlösen"
        },
        "state": {
          "all": "Alle",
          "canceled": "Storniert",
          "expired": "Verfallen",
          "gift": "Incentive",
          "issued": "Ausgestellt",
          "ordered": "Bestellt",
          "paid": "Bezahlt",
          "partial_redeemed": "Teilseingelöst",
          "redeemed": "Eingelöst",
          "title": "Bestellstatus"
        },
        "type": {
          "gift": "Incentive-Gutscheine",
          "product": "Produktgutscheine",
          "service": "Leistungsgutscheine",
          "stay": "Übernachtungsgutscheine",
          "title": "Gutscheintyp",
          "treatment": "Behandlungsgutscheine",
          "value": "Wertgutscheine"
        }
      },
      "searchPlaceholder": "Suchen Sie nach Bestellnummer, Namen oder Gutscheincode...",
      "title": "Bestellungen",
      "voucher": {
        "activationCode": "Freischaltcode",
        "amount": "Gutscheinwert",
        "amountLeft": "Restbetrag",
        "amountToPay": "Kaufbetrag",
        "expiresAt": "Verfällt am",
        "free": "Kostenlos",
        "language": "Sprache",
        "paymentType": "Bezahlart",
        "product": {
          "count": "Anzahl",
          "name": "Produkt",
          "price": "Preis"
        },
        "redeemCode": "Gutscheincode",
        "room": {
          "amount": "Zimmerpreis",
          "name": "Zimmer",
          "nights": "Anzahl Nächte",
          "persons": "Personen"
        },
        "treatment": {
          "count": "Anzahl",
          "duration": "Dauer",
          "minutes": "{count, plural, =1 {1 Minute} other {# Minuten}}",
          "name": "Behandlung",
          "price": "Preis"
        },
        "type": "Gutscheintyp",
        "validity": "Gültigkeit"
      }
    },
    "pageNotFound": {
      "button": "Zur Startseite",
      "content": "Leider konnte die gesuchte Seite nicht gefunden werden! <br>Entweder ist die URL nicht korrekt oder die Seite wurde entfernt bzw. umbenannt.",
      "title": "Seite wurde nicht gefunden!"
    },
    "routes": {
      "auditLog": {
        "title": "Aktivitätenprotokoll"
      },
      "dashboard": {
        "title": "Übersicht"
      },
      "help": {
        "title": "Hilfe und Support"
      },
      "orders": {
        "title": "Bestellungen"
      },
      "settings": {
        "general": "Allgemein",
        "legal": "Rechtliches",
        "paymentTypes": "Zahlungsmethoden",
        "shippingPackage": "Geschenks- & Versandoptionen",
        "texts": "Texte",
        "title": "Einstellungen"
      },
      "vouchers": {
        "categories": {
          "title": "Kategorien"
        },
        "title": "Gutscheine"
      },
      "widgets": {
        "navTitle": "Widgets & Vorlagen",
        "templates": {
          "title": "Gutscheinvorlagen"
        },
        "title": "Widgets"
      }
    },
    "select": {
      "empty": "Keine Optionen gefunden",
      "emptyOption": "Keine Auswahl",
      "noResults": "Keine Ergebnisse gefunden",
      "searchPlaceholder": "Suche"
    },
    "selectContainer": {
      "items": "{count, plural, =1 {# Element} other {# Elemente}} ausgewählt"
    },
    "settings": {
      "general": {
        "address": {
          "input": {
            "label": "Adresse",
            "message": "Adresse wird im Fußbereich des Gutscheins aufgedruckt"
          },
          "label": "Adresse",
          "modalTitle": "Adresse für den Gutschein"
        },
        "contactInfo": "Kontaktinformationen",
        "email": {
          "input": {
            "label": "E-Mail-Adresse",
            "message": "Diese E-Mail-Adresse erhält alle Bestätigungsmails"
          },
          "label": "E-Mail-Adresse",
          "modalTitle": "E-Mail-Adresse"
        },
        "inheritanceDescription": "Wenn dieses Symbol aktiviert (blau) ist, werden Daten oder Inhalte mit jenen Ihrer Organisation (Account) synchronisiert. Dadurch können Sie die Daten zentral in Ihrer Organisation für alle Apps verwalten.",
        "integration": {
          "asa": {
            "disable": {
              "action": "Verbindung trennen",
              "description": "Achtung!</br>Wenn Sie fortfahren wird die Synchronisation zwischen ASA und ADDITIVE+ GUTSCHEINE deaktiviert.</br>Die Verbindung kann jederzeit wieder aktiviert werden.",
              "title": "ASA Verbindung trennen"
            },
            "enable": {
              "action": "Verbindung einrichten",
              "description": "Nach erfolgreicher Einrichtung werden zukünftig Ihre Gutschein-Bestellungen zwischen ASA und ADDITIVE+ GUTSCHEINE synchronisiert.",
              "title": "ASA Verbindung herstellen"
            },
            "error": {
              "description": "Um mit der Einrichtung fortzufahren, muss eine gültige E-Mail-Adresse für die Organisation angegeben werden.",
              "title": "ASA Verbindung herstellen"
            },
            "excludeDiscounted": "Rabattierte Gutscheine nicht senden",
            "excludeIncentive": "Incentive Gutscheine nicht senden",
            "infoDialog": {
              "description": "Durch die Echtzeit-Synchronisation zwischen ADDITIVE+ GUTSCHEINE und ASA HOTEL werden Gutscheine, die über die Hotelsoftware eingelöst oder bezahlt werden, direkt in die App übertragen und umgekehrt.</br></br>Weiters ist das Einlösen bzw. Teileinlösen von Gutscheinen mit und ohne Reservierung als auch die Übertragung von Gutscheinbeträgen auf Rechnungen möglich.",
              "title": "Was ist die ASA Verbindung?"
            },
            "title": "ASA Verbindung",
            "upgradeForFeature": "Upgraden Sie auf \"Professional\", um die Synchronisation mit ASA freizuschalten."
          },
          "hgv": {
            "disable": {
              "action": "Verbindung trennen",
              "description": "Achtung!</br>Wenn Sie fortfahren wird die Synchronisation zwischen HGV-Buchungswidget und ADDITIVE+ GUTSCHEINE deaktiviert.</br>Die Verbindung kann jederzeit wieder aktiviert werden.",
              "title": "HGV-Buchungswidget Verbindung trennen"
            },
            "enable": {
              "action": "Verbindung einrichten",
              "description": "Nach erfolgreicher Einrichtung können ADDITIVE+ GUTSCHEINE bei einer Buchung im HGV-Buchungswidget eingelöst werden.",
              "title": "HGV-Buchungswidget Verbindung herstellen"
            },
            "error": {
              "description": "Um mit der Einrichtung fortzufahren, muss eine gültige E-Mail-Adresse für die Organisation angegeben werden.",
              "title": "HGV-Buchungswidget Verbindung herstellen"
            },
            "infoDialog": {
              "description": "Durch die Echtzeit-Synchronisation zwischen ADDITIVE+ GUTSCHEINE und HGV-Buchungswidget werden Gutscheine, die über das HGV-Buchungswidget eingelöst werden, direkt in die App übertragen.",
              "title": "Was ist die HGV-Buchungswidget Verbindung?"
            },
            "title": "HGV-Buchungswidget Verbindung",
            "upgradeForFeature": "Upgraden Sie auf \"Professional\", um die Synchronisation mit HGV-Buchungswidget freizuschalten."
          }
        },
        "notifications": {
          "incentive": "Für alle Incentive-Gutschein-Bestellungen",
          "notPaid": "Nur für bestellte, aber nicht bezahlte Gutschein-Bestellungen mit Überweisung als Zahlungsmethode",
          "paid": "Für alle bezahlten Gutschein-Bestellungen",
          "title": "E-Mail-Benachrichtigungen"
        },
        "organizationName": {
          "input": {
            "label": "Organisationsname"
          },
          "label": "Organisationsname",
          "modalTitle": "Organisationsname"
        },
        "prefix": {
          "description": "Ein Gutscheincode-Präfix ist ein Zeichen oder eine Gruppe von Zeichen, die am Anfang eines Gutscheincodes stehen, um ihn eindeutig für eine bestimmte Organisation zu machen. Wenn Sie verschiedene ADDITIVE+ GUTSCHEINE Lizenzen verwalten, ist es sinnvoll, bei jeder Organisation einen eindeutigen Präfix zuzuweisen. Dadurch können Sie die Gutscheine schneller und einfacher identifizieren und einlösen.",
          "title": "Was ist ein Gutscheincode-Präfix?"
        },
        "telephone": {
          "input": {
            "label": "Telefonnummer"
          },
          "label": "Telefonnummer",
          "modalTitle": "Telefonnummer"
        },
        "title": "Allgemein",
        "vouchers": {
          "prefix": "Gutscheincode-Präfix",
          "title": "Gutscheine"
        },
        "website": {
          "input": {
            "label": "Website"
          },
          "label": "Website",
          "modalTitle": "Website"
        }
      },
      "legal": {
        "description": "Hier können Sie Links zu den rechtlichen Texten auf Ihrer Website einfügen. Diese Links werden im Gutschein-Widget verwendet.",
        "disclaimerLink": {
          "input": {
            "label": "Link",
            "message": "Der Link verweist auf Ihren Haftungsausschluss."
          },
          "label": "Haftungsausschluss",
          "modalTitle": "Haftungsausschluss"
        },
        "privacyLink": {
          "input": {
            "label": "Link",
            "message": "Der Link verweist auf Ihre Datenschutzbestimmungen."
          },
          "label": "Datenschutzlink",
          "modalTitle": "Datenschutzlink"
        },
        "termsLink": {
          "input": {
            "label": "Link",
            "message": "Der Link verweist auf Ihre Nutzungsbedingungen."
          },
          "label": "Nutzungsbedingungen",
          "modalTitle": "Nutzungsbedingungen"
        },
        "title": "Rechtliches"
      },
      "paymentTypes": {
        "add": "Bezahlmethode hinzufügen",
        "bank_transfer": {
          "bankName": "Name der Bank",
          "bic": "BIC",
          "iban": "IBAN"
        },
        "concardis": {
          "apiKey": "API Schlüssel",
          "intro": "Geben Sie hier die Merchant ID und den API Schlüssel Ihres Concardis-Kontos ein, um Concardis als Zahlungsmethode anzubieten.",
          "merchantId": "Merchant ID"
        },
        "deleteDialog": {
          "description": "Sie sind dabei \"{paymentType}\" als Zahlungsmethode zu löschen. Wollen Sie wirklich forfahren?",
          "title": "Achtung!"
        },
        "empty": "Es wurden noch keine Zahlungsmethoden konfiguriert.<br/>Klicken Sie auf den unten stehenden Button, um eine Zahlungsmethode hinzuzufügen.",
        "errors": {
          "allAdded": "Sie können keine weiteren Zahlungsmodalitäten hinzufügen.",
          "countryCurrency": {
            "description": "Dieser Zahlungsanbieter unterstützt die ausgewählte Währung nicht in Ihrem Land.",
            "title": "Achtung!"
          },
          "deactivatePaymentType": {
            "description": "Es muss mindestens eine Zahlungsmethode zusätzlich zu \"Zahlung vor Ort\" aktiviert sein.",
            "title": "Zahlungsmethode kann nicht deaktiviert werden"
          },
          "deletePaymentType": {
            "description": "Es muss mindestens eine Zahlungsmethode zusätzlich zu \"Zahlung vor Ort\" aktiviert sein.",
            "title": "Zahlungsmethode kann nicht gelöscht werden"
          },
          "missingValue": {
            "description": "Für die Aktivierung dieser Zahlungsmethode müssen alle Felder vollständig ausgefüllt sein. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es anschließend erneut.",
            "title": "Achtung!"
          },
          "planRestrictions": {
            "plan_allowed_payment_types_exceeded": {
              "description": "Mit Ihrer \"Professional\"-Lizenz können Sie zusätzlich zu \"PayPal\" und \"Banküberweisung\" maximal eine weitere Zahlungsmethode aktivieren.",
              "title": "Achtung!"
            },
            "starter_not_allowed_payment_type": {
              "description": "Mit Ihrer \"Starter\"-Lizenz können Sie nur \"Überweisung\" und/oder \"PayPal\" aktivieren.",
              "title": "Achtung!"
            }
          }
        },
        "gastro_pool": {
          "intro": "Geben Sie hier Ihren gastropool-Key (alte Version) ein, um gastropool (alte Version) als Zahlungsmethode anzubieten.",
          "key": "Key"
        },
        "gastro_pool_v3": {
          "intro": "Geben Sie hier Ihren gastropool-Key ein, um gastropool als Zahlungsmethode anzubieten.",
          "key": "Key"
        },
        "hogast": {
          "intro": "Geben Sie hier Ihren hogast-Key (alte Version) ein, um hogast (alte Version) als Zahlungsmethode anzubieten.",
          "key": "Key"
        },
        "hogast_v3": {
          "intro": "Geben Sie hier Ihren hogast-Key ein, um hogast als Zahlungsmethode anzubieten.",
          "key": "Key"
        },
        "klarna": {
          "intro": "Geben Sie hier API Benutzername und Passwort an, um Klarna als Zahlungsmethode anzubieten.",
          "password": "API Passwort",
          "username": "API Benutzername (UID)"
        },
        "nexi": {
          "apiKey": "API Schlüssel",
          "correlationId": "Correlation ID",
          "intro": "Geben Sie hier die Correlation ID und den API Schlüssel Ihres Nexi-Kontos ein, um Nexi als Zahlungsmethode anzubieten."
        },
        "openGuide": "Leitfaden öffnen",
        "oppwa": {
          "activateApplePay": "Apple Pay aktivieren",
          "activateSofortueberweisung": "Sofortüberweisung aktivieren",
          "entityId": "Entity ID",
          "intro": "Geben Sie hier Ihre hobex-Zugangsdaten ein, um hobex als Zahlungsmethode anzubieten.",
          "token": "Access Token"
        },
        "paypal": {
          "intro": "Geben Sie hier Benutzername, Passwort und Signatur Ihres PayPal-Business-Kontos ein, um PayPal als Zahlungsmethode anzubieten.",
          "password": "Passwort",
          "signature": "Signature",
          "username": "Benutzername"
        },
        "sia_vpos": {
          "apiResultKey": "Chiave Esito API",
          "apiStartKey": "Chiave Avvio",
          "intro": "Geben Sie hier die MID, sowie Chiave Avvio und Chiave Esito API Ihres SIA VPOS-Kontos ein, um SIA VPOS als Zahlungsmethode anzubieten.",
          "shopId": "MID"
        },
        "six_payments": {
          "apiPassword": "API Passwort",
          "apiUser": "API Benutzername",
          "customerId": "Kunden ID",
          "intro": "Geben Sie hier API Benutzername und Passwort, sowie Kunden ID und Terminal ID Ihres SIX Payment Services-Kontos ein, um SIX Payment Services als Zahlungsmethode anzubieten.",
          "terminalId": "Terminal ID"
        },
        "stripe": {
          "intro": "Geben Sie hier Ihren Publishable- und Secret Key ein, um Stripe als Zahlungsmethode anzubieten.",
          "publishableKey": "Publishable Key",
          "secretKey": "Secret Key"
        },
        "title": "Zahlungsmethoden",
        "vpos": {
          "id": "ID",
          "intro": "Geben Sie hier Ihre Virtual-POS-ID ein, um Virtual-POS als Zahlungsmethode anzubieten."
        }
      },
      "shipping": {
        "createDialog": {
          "options": {
            "package": "Geschenksoption",
            "shipping": "Versandoption"
          },
          "title": "Neu erstellen"
        },
        "emptyPackages": "Sie haben noch keine Geschenkoptionen erstellt.<br> Diese können Sie per Klick auf den \"+\"-Button erstellen.",
        "errors": {
          "accessRights": {
            "message": "Sie haben nicht die nötigen Zugriffsberechtigungen, um diese Aktion auszuführen."
          },
          "defaultEdit": {
            "description": "Diese Versandoption kann nicht verändert werden.",
            "title": "Fehler!"
          },
          "uniqueCountries": {
            "description": "Jedes Land darf nur ein mal pro Versandoption vorkommen.",
            "title": "Land öfters verwendet!"
          }
        },
        "title": "Versandoptionen",
        "upgradeForFeature": "<span class=\"blue\">Upgraden</span> Sie auf \"Professional\", um Geschenk- und Versandoptionen anlegen zu können."
      },
      "texts": {
        "customInfo": "Benutzerdefinierte Informationen",
        "emailValediction": {
          "input": {
            "label": "Signatur"
          },
          "label": "Signatur",
          "modalTitle": "Signatur"
        },
        "title": "Texte",
        "voucherGreeting": {
          "input": {
            "label": "Grußbotschaft auf Gutschein",
            "message": "Vorgeschlagener Beispielgrußtext des Schenkenden an den Beschenkten."
          },
          "label": "Vordefinierte Grußbotschaft",
          "modalTitle": "Vordefinierte Grußbotschaft"
        },
        "voucherIntro": {
          "input": {
            "label": "Einleitungstext",
            "message": "Einleitender Text oberhalb der Kategorien- und Gutscheineansicht"
          },
          "label": "Einleitungstext"
        },
        "voucherTermsOfUse": {
          "input": {
            "label": "Infotext auf Gutschein",
            "message": "Fixer Infotext, welcher im Fußbereich aller Gutscheine aufgedruckt wird."
          },
          "label": "Kurze AGBs",
          "modalTitle": "Kurze AGBs"
        }
      },
      "title": "Einstellungen"
    },
    "style": {
      "loadingMessage": "Wird geladen..."
    },
    "toast": {
      "bulkDelete": "{count, plural, =1 {Ein Element} other {# Elemente}} gelöscht",
      "orderUpdated": {
        "description": "Der Gutschein wird nun neu erstellt. Dies kann einen Augenblick dauern.",
        "title": "Änderungen gespeichert"
      },
      "required": "Wert darf nicht leer sein",
      "success": "Aktion war erfolgreich",
      "unexpectedError": "Ein unerwarteter Fehler ist aufgetreten.",
      "wrongOrderState": {
        "description": "Der Gutschein kann nicht reaktiviert werden, da er weder eingelöst, noch storniert wurde.",
        "title": "Gutschein nicht eingelöst oder storniert"
      }
    },
    "toasts": {
      "loading": {
        "description": "Etwas Geduld bitte, wir sind fast fertig!",
        "title": "Das dauert länger als erwartet."
      }
    },
    "uiActions": {
      "apply": "Anwenden",
      "back": "Zurück",
      "close": "Schließen",
      "confirm": "Bestätigen",
      "delete": "Löschen",
      "edit": "Bearbeiten",
      "export": "Exportieren",
      "info": "Informationen",
      "menu": "Menü",
      "more": "Mehr",
      "reset": "Zurücksetzen",
      "save": "Speichern",
      "saveAndPublish": "Speichern und veröffentlichen",
      "search": "Suchen"
    },
    "uiApplicationNavigationDrawer": {
      "accountSwitch": {
        "empty": "Keine Organisation gefunden",
        "organizationSettings": "Organisationseinstellungen",
        "searchPlaceholder": "Suchen"
      },
      "appsList": {
        "goToApps": "zu den Apps",
        "myApps": "Meine Apps",
        "planNames": {
          "10": {
            "starter_yearly": "Standard"
          },
          "12": {
            "professional_yearly": "Segmentation",
            "starter_yearly": "Customer Data Platform"
          },
          "50": {
            "enterprise_yearly": "MARKETING CLOUD",
            "professional_yearly": "AUTOMATION CLOUD",
            "starter_yearly": "LEAD CLOUD"
          },
          "51": {
            "professional_yearly": "Attribution",
            "starter_yearly": "Tracking"
          },
          "default": {
            "enterprise_monthly": "Enterprise",
            "enterprise_yearly": "Enterprise",
            "professional_monthly": "Professional",
            "professional_yearly": "Professional",
            "standard": "Standard",
            "starter_monthly": "Starter",
            "starter_yearly": "Starter"
          }
        },
        "showMoreApps": "Weitere Apps entdecken"
      }
    },
    "uiCategoryCard": {
      "contentCountError": "Diese Kategorie kann nicht gelöscht werden, da sie noch Inhalte enthält.",
      "titleError": "Achtung!"
    },
    "uiCollapsibleTable": {
      "collapsibleRowDescription": "{count, plural, =1 {# Unterkategorie} other {# Unterkategorien}}"
    },
    "uiColorComposition": {
      "dialog": {
        "preview": "Vorschau",
        "save": "Speichern"
      }
    },
    "uiContentCount": {
      "count": "{count, plural, =1 {# Eintrag} other {# Einträge}}",
      "filterCount": "{count, plural, =1 {# Eintrag} other {# Einträge}} gefunden",
      "pages": "Seite <span class=\"primary ph05\">{page}</span> / <span class=\"pl05\">{total}</span>"
    },
    "uiContentInfo": {
      "showLess": "Weniger anzeigen",
      "showMore": "Mehr anzeigen"
    },
    "uiDateFilter": {
      "label": "Betrachtungszeitraum"
    },
    "uiDatePicker": {
      "filters": {
        "custom_date": "Benutzerdefiniert",
        "last_month": "Letzter Monat",
        "last_thirty_days": "Letzte 30 Tage",
        "last_week": "Letzte Woche",
        "this_month": "Dieser Monat",
        "this_week": "Diese Woche"
      },
      "weekDaysShort": {
        "Fri": "Fr",
        "Mon": "Mo",
        "Sat": "Sa",
        "Sun": "So",
        "Thu": "Do",
        "Tue": "Di",
        "Wed": "Mi"
      }
    },
    "uiDatetimePicker": {
      "date": "Datum",
      "endDate": "Enddatum",
      "endTime": "Enduhrzeit",
      "error": {
        "beforeToday": "Vergangenes Datum",
        "false": "Ungültiges Datum"
      },
      "startDate": "Startdatum",
      "startTime": "Startuhrzeit",
      "time": "Uhrzeit"
    },
    "uiDiscardChanges": {
      "discardAction": "Verwerfen",
      "message": "Sollen ungespeicherte Änderungen verworfen werden?",
      "title": "Achtung!"
    },
    "uiEditor": {
      "actions": {
        "aiActions": {
          "aiAdjustTone": "Ton anpassen",
          "aiEmojify": "Emojis hinzufügen",
          "aiFixSpellingAndGrammar": "Rechtschreibung und Grammatik",
          "aiRephrase": "Umschreiben",
          "aiRestructure": "Formatieren",
          "aiShorten": "Kürzen",
          "aiSimplify": "Vereinfachen",
          "aiTextPrompt": "Eigener KI-Befehl",
          "de": "Auf Deutsch übersetzen",
          "en": "Auf Englisch übersetzen",
          "it": "Auf Italienisch übersetzen"
        },
        "blockquote": "Zitat",
        "bold": "Fett",
        "bulletList": "Aufzählungsliste",
        "code": "Code",
        "codeBlock": "Code",
        "editWithAi": "KI-Assistent",
        "headings": {
          "h1": "Überschrift 1",
          "h2": "Überschrift 2",
          "h3": "Überschrift 3",
          "h4": "Überschrift 4",
          "p": "Normal"
        },
        "headingsShort": {
          "h1": "H1",
          "h2": "H2",
          "h3": "H3",
          "h4": "H4",
          "p": "P"
        },
        "horizontalRule": "Trennlinie",
        "italic": "Kursiv",
        "link": "Link",
        "numberedList": "Nummerierte Liste",
        "orderedList": "Nummerierte Liste",
        "placeholder": "Platzhalter",
        "quote": "Zitat",
        "redo": "Wiederherstellen",
        "spacer": "Trennlinie",
        "translate": "Übersetzen",
        "underline": "Unterstrichen",
        "undo": "Rückgängig",
        "unformat": "Formatierung entfernen"
      },
      "aiDialog": {
        "aiAdjustTone": {
          "description": "Wählen Sie den Ton, den Sie für Ihren Text bevorzugen.",
          "inputLabel": "Ton",
          "title": "Ton anpassen"
        },
        "aiTextPrompt": {
          "description": "Geben Sie einen benutzerdefinierten Prompt ein, um den Text zu verändern.",
          "inputLabel": "Prompt",
          "title": "Benutzerdefinierter Prompt"
        },
        "save": "Speichern"
      },
      "colors": {
        "accent": "Akzentfarbe",
        "ambient": "Umgebungsfarbe",
        "default": "Keine Farbe",
        "main": "Hauptfarbe"
      },
      "urlDialog": {
        "openInNewTab": "In neuem Tab öffnen",
        "save": "Speichern",
        "text": "Text",
        "type": {
          "name": "Link-Typ",
          "options": {
            "email": "E-Mail",
            "mailto:": "E-Mail",
            "tel": "Telefon",
            "tel:": "Telefon",
            "weblink": "Weblink"
          }
        },
        "url": "URL",
        "urlNotValid": {
          "email": "Diese E-Mail-Adresse ist nicht gültig",
          "mailto:": "Diese E-Mail-Adresse ist nicht gültig",
          "tel": "Diese Telefonnummer ist nicht gültig",
          "tel:": "Diese Telefonnummer ist nicht gültig",
          "weblink": "Diese URL ist nicht gültig"
        }
      }
    },
    "uiFilterInputs": {
      "buttonText": "Filtern",
      "daterange": {
        "end": "bis",
        "start": "von"
      }
    },
    "uiFormControl": {
      "recommendedLengthWarning": "Wert überschreitet die empfohlene Länge von {recommendedLength} Zeichen"
    },
    "uiFormDialog": {
      "save": "Speichern"
    },
    "uiFormMessage": {
      "characters": "{charCount} Zeichen"
    },
    "uiListView": {
      "bulkDelete": "{count, plural, =1 {Ein Element} other {# Elemente}} gelöscht",
      "common": {
        "bulkDelete": {
          "action": "Alle löschen",
          "description": "Die ausgewählten Inhalte werden dadurch unwiederruflich gelöscht und können nicht mehr wiederhergestellt werden.<br/>Sollen die ausgewählten Inhalte wirklich gelöscht werden?"
        },
        "deleteContent": {
          "description": "Der Inhalt wird dadurch unwiederruflich gelöscht und kann nicht mehr wiederhergestellt werden.<br/>Soll <span class=\"font-medium\">\"{name}\"</span> wirklich gelöscht werden?",
          "title": "Achtung!"
        }
      },
      "conflict_errors": {
        "person": {
          "description": "Diese Person kann nicht gelöscht werden, da es von anderen Inhalten verwendet wird. Lösen Sie die Abhängigkeiten bevor Sie diese Person löschen.",
          "title": "Diese Person ist in Verwendung"
        }
      },
      "delete": "Löschen",
      "empty": "Es gibt noch keine Inhalte.<br/>Klicken Sie auf den untenstehenden Button um einen Inhalt zu erstellen.",
      "error": "Ein unerwarteter Fehler ist aufgetreten.",
      "errors": {
        "rate_in_use": "Diese Rate kann nicht gelöscht werden, da Sie in einem Preiszeitraum verwendet wird.",
        "title": "Achtung!",
        "used_in_post": "Diese Person kann nicht gelöscht werden, da sie in einem Beitrag verwendet wird.",
        "vouchers_left": "Dieser Inhalt kann nicht gelöscht werden, da er in einem Gutschein verwendet wird."
      },
      "noMatch": "Ihre Filterung ergab keine Treffer.",
      "properties": {
        "published": "öffentlich",
        "unpublished": "nicht öffentlich"
      }
    },
    "uiMonthDayPicker": {
      "day": "Tag",
      "month": "Monat"
    },
    "uiNavigationBarFilterabel": {
      "dynamicFilters": {
        "buttonText": "Weitere Filter",
        "error": {
          "retryButtonText": "Es ist ein Fehler aufgetreten! Jetzt neu versuchen"
        },
        "navigationDrawer": {
          "title": "Weitere Filter"
        }
      },
      "searchInputDefaultPlaceholder": "Durchsuchen"
    },
    "uiOnboarding": {
      "actions": {
        "back": "Zurück",
        "endOnboarding": "Jetzt loslegen",
        "next": "Weiter",
        "skip": "Überspringen"
      }
    },
    "uiPublishResource": {
      "description": "Wählen Sie hier aus welche Sprachen Sie veröffentlichen möchten. Zudem können Sie den Rahmen der Veröffentlichung zeitlich einschränken.",
      "descriptionNoLang": "Wählen Sie den Zeitpunkt für die Veröffentlichung aus.",
      "errors": {
        "after": "Muss nach dem Startdatum sein",
        "before": "Muss vor dem Enddatum sein",
        "required": "Darf nicht leer sein"
      },
      "languages": "Sprachen",
      "limitTime": "Veröffentlichungszeitraum einschränken",
      "save": "Speichern",
      "settings": "Einstellungen",
      "title": "Veröffentlichen"
    },
    "uiRangeSlider": {
      "errorMessage": "Der Wert muss eine positive Ganzzahl sein",
      "max": "max",
      "min": "min"
    },
    "uiTable": {
      "empty": "Keine Daten vorhanden",
      "error": "Ein Fehler ist aufgetreten",
      "retry": "Nochmal versuchen",
      "showLess": "Weniger anzeigen",
      "showMore": "Mehr anzeigen"
    },
    "uiTags": {
      "error": "Dieser Wert wurde bereits hinzugefügt",
      "interests": {
        "interests": "Interessen",
        "travelMotivation": "Reisemotive",
        "travelTime": "Reisezeiten"
      },
      "placeholder": "Tag hinzufügen",
      "tags": "Tags"
    },
    "uiTimepicker": {
      "errorMessage": "Ungültige Zeitangabe",
      "timeUnit": "Uhr"
    },
    "uiToast": {
      "success": "Aktion erfolgreich durchgeführt",
      "unexpectedError": "Ein unerwarteter Fehler ist aufgetreten"
    },
    "vouchers": {
      "category": {
        "deleteCategory": {
          "description": "Die Kategorie wird dadurch unwiderruflich gelöscht und kann nicht mehr wiederhergestellt werden.<br/>Soll <span class=\"font-medium\">\"{name}\"</span> wirklich gelöscht werden?",
          "title": "Achtung!"
        },
        "newCategory": "Neue Kategorie",
        "properties": {
          "description": "Beschreibung",
          "image": "Bild",
          "name": "Name"
        },
        "voucherCount": "{count, plural, =1 {1 Gutschein} other {# Gutscheine}}",
        "voucherCountError": "Diese Kategorie kann nicht gelöscht werden, da sie noch Gutscheine enthält."
      },
      "createDialog": {
        "productDescription": "Wählen Sie Produkte aus, welche Sie als Gutscheine anbieten möchten.",
        "serviceDescription": "Definieren Sie Inklusivleistungen und einen fixen Preis für den Gutschein.",
        "stayDescription": "Wählen Sie Zimmer aus, um Aufenthalte als Gutscheine anbieten zu können.",
        "title": "Neuen Gutschein erstellen",
        "treatmentDescription": "Wählen Sie Behandlungen aus, welche Sie als Gutscheine anbieten möchten.",
        "valueDescription": "Der Käufer wählt selbst den Wert des Gutscheins."
      },
      "description": "Erstellen und veröffentlichen Sie verschiedene Gutscheine. Wählen Sie hierfür unter den verschiedenen Gutscheinarten die passende Vorlage aus. Die Verwendung der Kategorien ermöglicht Ihnen dabei einen besseren Überblick zu bewahren. Inhouse-Gutscheine werden direkt im Menüpunkt Bestellungen abgespeichert.",
      "empty": "Es gibt noch keine Gutscheine.<br/>Klicken Sie auf den untenstehenden Button, um einen Gutschein zu erstellen.",
      "expirationFormats": {
        "custom": "Benutzerdefiniert",
        "end_of_next_year": "Ende des nächsten Jahres",
        "end_of_year": "Ende des Jahres",
        "five_years": "5 Jahre",
        "indefinitely": "Unbegrenzt",
        "one_year": "1 Jahr",
        "three_years": "3 Jahre",
        "three_years_end_of_year": "Ende des dritten Jahres",
        "two_years": "2 Jahre"
      },
      "noMatch": "Ihre Filterung ergab keine Treffer.",
      "searchPlaceholder": "Suchen Sie z.B. Leistungsgutschein",
      "title": "Gutscheine",
      "types": {
        "dynamic": "Benutzerdefiniert",
        "gift": "Incentive-Gutschein",
        "product": "Produktgutschein",
        "service": "Leistungsgutschein",
        "stay": "Übernachtungsgutschein",
        "treatment": "Behandlungsgutschein",
        "value": "Wertgutschein"
      },
      "voucher": {
        "bulkDeleteVouchers": {
          "action": "Alle löschen",
          "description": "Die ausgewählten Gutscheine werden dadurch unwiderruflich gelöscht und können nicht mehr wiederhergestellt werden.<br/>Sollen die ausgewählten Gutscheine wirklich gelöscht werden?",
          "title": "Achtung!"
        },
        "deleteVoucher": {
          "description": "Der Gutschein wird dadurch unwiderruflich gelöscht und kann nicht mehr wiederhergestellt werden.<br/>Soll <span class=\"font-medium\">\"{name}\"</span> wirklich gelöscht werden?",
          "title": "Achtung!"
        },
        "discount": "{amount, number, EUR} <span class='red'>&nbsp;-{percentage}%<span>",
        "free": "Kostenlos",
        "minStays": "{count, plural, =0 {Keine Angabe} other {#}}",
        "multimedia": {
          "imageWarning": "Es können nur Bilder mit einer Breite von mindestens 800px ausgewählt werden."
        },
        "numberOfProducts": "{count, plural, =0 {Keine Produkte ausgewählt} =1 {Ein Produkt} other {# Produkte}}",
        "numberOfTreatments": "{count, plural, =0 {Keine Behandlungen ausgewählt} =1 {Eine Behandlung} other {# Behandlungen}}",
        "personCount": "{count, plural, =0 {Keine Angabe} other {#}}",
        "product": {
          "columns": {
            "name": "Produkt",
            "price": "Preis"
          },
          "editProducts": "Produkte bearbeiten",
          "empty": "Für den Gutschein wurden noch keine öffentlichen Produkte ausgewählt.",
          "invalidProductsWarning": "Dieser Gutschein ist öffentlich, kann aber nicht verwendet werden, da keine Produkte mit gültigem Preis in den gewählten Kategorien existieren. Erstellen Sie ein Produkt mit gültigem Preis für diesen Gutschein.",
          "products": "Produkte",
          "showProduct": "Produkt anzeigen"
        },
        "productCategoryCount": "{count, plural, =0 {Keine Produktkategorien ausgewählt} =1 {Eine Produktkategorie ausgewählt} other {# Produktkategorien ausgewählt}}",
        "properties": {
          "amount": "Preis",
          "category": "Kategorie",
          "description": "Beschreibung",
          "discountPeriodEnd": "Ende",
          "discountPeriodStart": "Beginn",
          "discountedAmount": "Rabattierter Preis",
          "discountedPrice": "Rabattierter Preis",
          "errors": {
            "not_exists": {
              "description": "Für eine oder mehrere Behandlungen fehlen die erforderlichen Übersetzungen. Überprüfen Sie, ob die Behandlungen in allen Sprachen des Gutscheins übersetzt wurden.",
              "title": "Fehlende Übersetzungen"
            },
            "roomTypeTranslationsMissing": {
              "buttonText": "Zimmer überprüfen",
              "description": "Für einen oder mehrere Zimmertypen fehlen die erforderlichen Übersetzungen. Überprüfen Sie, ob die Zimmertypen in allen Sprachen des Gutscheins übersetzt wurden.",
              "title": "Fehlende Übersetzungen"
            }
          },
          "hasDiscountPeriod": "Rabatt zeitlich begrenzen",
          "images": "Bilder",
          "infoMessages": {
            "description": "Wird nicht auf den Gutschein gedruckt",
            "name": "Der Titel wird auf den Gutschein gedruckt. Der Einbau von \"Gutschein\" als Wort wird daher empfohlen."
          },
          "interestTags": {
            "info": "Hinterlegen Sie hier passende Schlagwörter, um Ihre Gäste und potentiellen Gäste noch gezielter mit jenen Inhalten zu bespielen, welche ihren Präferenzen entsprechen.",
            "placeholder": "Interesse / Reisemotiv / Liebste Reisezeit hinzufügen",
            "title": "Interessen / Reisemotive / Liebste Reisezeiten"
          },
          "isDiscounted": "Gutschein rabattieren",
          "isLocked": "Freischaltcode anwenden",
          "language": "Sprache",
          "maxAmount": "Maximaler Wert",
          "minAmount": "Minimaler Wert",
          "minStays": "Mindestübernachtungen",
          "name": "Titel",
          "personCount": "Personenanzahl",
          "price": "Preis",
          "productCategories": "Produktkategorien",
          "published": "öffentlich",
          "roomTypes": "Zimmertypen",
          "services": "Leistungen",
          "stays": "Übernachtungen",
          "treatmentCategories": "Behandlungskategorien",
          "type": "Gutscheintyp",
          "unlockCode": "Freischaltcode",
          "unpublished": "nicht öffentlich",
          "validityPeriod": "Gültigkeit",
          "voucherValue": "Gutscheinwert",
          "warnings": {
            "services": "Sie überschreiten die empfohlene, maximale Zeichenanzahl. Denken Sie daran, den Gutschein zu kontrollieren bevor Sie ihn veröffentlichen."
          }
        },
        "publicRooms": "Es können nur Zimmer ausgewählt werden, welche öffentlich sind und einen Basispreis besitzen.",
        "publish": {
          "date": "Der Gutschein ist zwischen {from} und {to} öffentlich.",
          "description": "Gutschein veröffentlichen",
          "info": "Bitte fügen Sie dem Gutschein ein Bild hinzu, um ihn veröffentlichen zu können."
        },
        "rooms": "{count, plural, =0 {Keine Zimmer ausgewählt} other {# Zimmer}}",
        "sections": {
          "discount": {
            "freeHelpText": "Hierbei handelt es sich um einen Gutschein, der kostenlos bestellt werden kann. Beachten Sie dabei, die Leistungen so zu beschreiben, dass ein mehrmaliges Einlösen des Gutscheins vermieden wird, falls dies gewünscht ist.",
            "label": "Rabattierung",
            "options": {
              "discounted": "Rabattierung",
              "free": "Kostenlos / Als Incentive",
              "noDiscount": "Keine Rabattierung"
            },
            "title": "Rabatt / Incentive"
          },
          "general": "Allgemein",
          "unlockCode": {
            "description": "Wenn Sie den Freischaltcode aktivieren, wird dieser Gutschein auf Ihrer Website ausgeblendet und erst angezeigt, sobald der dazugehörige Freischaltcode eingegeben wurde.",
            "generate": "Freischaltcode generieren",
            "infoMessage": "Vermeiden Sie ähnlich aussehende Zeichen, wie bspw. O und 0 oder l und 1.",
            "label": "Beachten Sie, dass der festgelegte Freischaltcode eindeutig sein muss.",
            "title": "Freischaltcode"
          }
        },
        "stay": {
          "columns": {
            "basePrice": "Basispreis",
            "name": "Zimmer",
            "occupancy": "Belegung",
            "priceInformation": "Preisbezug"
          },
          "editRoomTypes": "Zimmer bearbeiten",
          "empty": "Für den Gutschein wurden noch keine öffentlichen Zimmer ausgewählt.",
          "invalidRoomTypesWarning": "Dieser Gutschein ist öffentlich, kann aber nicht verwendet werden, da keine Zimmer mit gültigem Preis in den gewählten Kategorien existieren. Erstellen Sie ein Zimmer mit gültigem Preis für diesen Gutschein.",
          "per_person": "Pro Person",
          "per_room": "Pro Wohneinheit",
          "showRoomType": "Zimmer anzeigen",
          "v1WidgetWarning": "Sie nutzen aktuell die alte Version des Gutschein-Widgets. Das Feature \"Preisbezug (pro Person/Wohneinheit)\" ist nur in Verbindung mit dem neuen Widget möglich, welches von Ihrem Website-Dienstleister eingebaut werden kann. Die notwendigen Codes finden Sie im Menüpunkt \"Widgets & Vorlagen\"."
        },
        "stays": "{count, plural, =0 {Keine Übernachtung} =1 {# Übernachtung} other {# Übernachtungen}}",
        "treatment": {
          "columns": {
            "duration": "Dauer",
            "name": "Behandlung",
            "price": "Preis"
          },
          "editTreatments": "Behandlungen bearbeiten",
          "empty": "Für den Gutschein wurden noch keine öffentlichen Behandlungen ausgewählt.",
          "invalidTreatmentsWarning": "Dieser Gutschein ist öffentlich, kann aber nicht verwendet werden, da keine Behandlungen mit gültigem Preis in den gewählten Kategorien existieren. Erstellen Sie eine Behandlung mit gültigem Preis für diesen Gutschein.",
          "minutes": "{count} Min.",
          "showTreatment": "Behandlung anzeigen",
          "treatments": "Behandlungen"
        },
        "treatmentCategoryCount": "{count, plural, =0 {Keine Behandlungskategorien ausgewählt} =1 {Eine Behandlungskategorie ausgewählt} other {# Behandlungskategorien ausgewählt}}"
      }
    },
    "widget": {
      "errorMessage": "Beim Laden des Widgets ist ein Fehler aufgetreten.",
      "helpTexts": {
        "inheritanceDescription": "Wenn dieses Symbol aktiviert (blau) ist, werden Daten oder Inhalte mit jenen Ihrer Organisation (Account) synchronisiert. Dadurch können Sie die Daten zentral in Ihrer Organisation für alle Apps verwalten."
      },
      "loadingMessage": "Das Widget wird für Sie initialisiert.",
      "retry": "Nochmal versuchen"
    },
    "widgets": {
      "configurator": {
        "colors": {
          "accent": "Akzentfarbe",
          "ambient": "Umgebungsfarbe",
          "error": "Fehlermeldung",
          "main": "Hauptfarbe",
          "stateColors": "Weitere Farbeinstellungen",
          "title": "Farben",
          "titleFailure": "Überschrift bei fehlerhafter Bestellung",
          "titleSuccess": "Überschrift bei erfolgreicher Bestellung"
        },
        "general": {
          "borderRadius": "Abrundungen",
          "boxShadow": "Schlagschatten",
          "categoryDescription": "Kategorienbeschreibung",
          "layout": "Layout",
          "overlay": "Gutschein-Vorschau",
          "ownContent": "Eigene Inhalte",
          "placeholderContent": "Platzhalterinhalte",
          "scrollOffset": "Scrollverhalten",
          "scrollOffsetDesktop": "Scrollversatz Desktop",
          "scrollOffsetMobile": "Scrollversatz Mobile",
          "scrollOnInitToggle": "Scrollen bei Initialisierung",
          "settings": "Widget-Konfiguration",
          "template": "Gutscheinvorlage",
          "title": "Allgemein",
          "toggleAdvanced": "Weitere Optionen {boolean, select, true {ausblenden} other {einblenden}}",
          "tooltip": "Tooltips",
          "zIndex": "z-index (CSS-Eigenschaft)"
        },
        "helpTexts": {
          "colors": {
            "accent": "Auszeichnungsfarbe für Buttons und interaktive Elemente",
            "ambient": "Hintergrundfarbe der Umgebung, in der das Widget eingebunden wird",
            "error": "Farbe der Fehlermeldungen",
            "main": "Primäre Schriftfarbe im gesamten Widget",
            "titleFailure": "Farbe der Überschrift, welche nach einer fehlerhaften Bestellung angezeigt wird",
            "titleSuccess": "Farbe der Überschrift, welche nach einer erfolgreichen Bestellung angezeigt wird"
          },
          "general": {
            "borderRadius": "Stärke der Abrundungen im Widget (CSS-Eigenschaft).",
            "boxShadow": "Aussehen der Schlagschatten im Widget (CSS-Eigenschaft).",
            "categoryDescription": "Soll die Kategorienansicht mit Beschreibungstext oder mit Hintergrundbild dargestellt werden?",
            "overlay": "z-index (CSS-Eigenschaft) für die Gutschein-Vorschau.",
            "scrollOffsetDesktop": "Scrollversatz (Offset) der für Scrolloperationen im Widget verwendet werden soll. Für Containerbreite >= 800px.",
            "scrollOffsetMobile": "Scrollversatz (Offset) der für Scrolloperationen im Widget verwendet werden soll. Für Containerbreite < 800px.",
            "scrollOnInitToggle": "Soll beim Laden der Seite automatisch zur Gutschein-Vorschau gescrollt werden?",
            "template": "Gutscheinvorlage, welche für dieses Widget verwendet werden soll.",
            "templateDisabled": "Die Gutscheinvorlage kann nur verändert werden, wenn die Option \"Eigene Inhalte\" aktiv ist.",
            "tooltip": "z-index (CSS-Eigenschaft), welcher für alle Tooltips im Widget verwendet werden soll."
          },
          "tracking": {
            "description": "Falls Sie die Tracking-Events mittels \"Data Layer\" im Google Tag Manager verarbeiten, können Sie die einzelnen Plattform-Einstellungen deaktivieren. Die Werte werden im benutzerdefinierten Event \"AdditiveApps\" bereitgestellt."
          },
          "typography": {
            "primary": "Schriftart der Überschriften",
            "secondary": "Schriftart der Fließtexte",
            "warning": "Benutzerdefinierte Schriftfamilien werden nicht in der Vorschau angezeigt."
          }
        },
        "template": {
          "helpTexts": {
            "colors": {
              "accent": "Auszeichnungsfarbe für herausstehende Elemente",
              "ambient": "Hintergrundfarbe der Vorlage",
              "main": "Primäre Schriftfarbe der gesamten Vorlage"
            }
          },
          "typography": {
            "properties": {
              "h1": {
                "description": "",
                "title": "Überschrift"
              },
              "label": {
                "description": "",
                "title": "Inklusivleistungen und Footer"
              },
              "p": {
                "description": "",
                "title": "Grußbotschaft"
              },
              "small": {
                "description": "",
                "title": "Inklusivleistungen"
              }
            }
          }
        },
        "title": "Widget-Konfiguration",
        "tracking": {
          "facebook": "Facebook Pixel",
          "google": "Google Analytics",
          "openGuide": "Tracking Leitfaden öffnen",
          "title": "Tracking"
        },
        "typography": {
          "defaultFont": "Standard",
          "fontColor": "Schriftfarbe",
          "fontFamilies": "Schriftfamilien",
          "fontFamily": "Schriftfamilie",
          "fontOptions": {
            "default": "Standard",
            "sansserif": "Serifenlos",
            "serif": "Serif"
          },
          "fontSize": "Schriftgröße",
          "fonts": "Schriften",
          "lineHeight": "Zeilenhöhe",
          "primary": "Primär",
          "properties": {
            "button": {
              "description": "",
              "title": "Buttons"
            },
            "h2": {
              "description": "",
              "title": "Überschriften"
            },
            "h3": {
              "description": "",
              "title": "Unterüberschriften"
            },
            "label": {
              "description": "",
              "title": "Labels"
            },
            "p": {
              "description": "",
              "title": "Text"
            },
            "small": {
              "description": "",
              "title": "Text (klein)"
            }
          },
          "secondary": "Sekundär",
          "settings": {
            "embedFonts": {
              "description": "Wenn diese Option aktiviert ist, werden alle verwendeten Schriftarten im Widget eingebunden. Dies kann zu längeren Ladezeiten führen.",
              "title": "Schriften einbinden"
            },
            "fontName": {
              "dialog": {
                "bold": "Bold",
                "confirm": "Bestätigen",
                "description": "Hier können die Namen der verschiedenen Schriftarten definiert werden. Die gewählte Bezeichnung muss mit jener der Website übereinstimmen. Falls der Schriftschnitt nicht durch unterschiedliche Bezeichnungen sondern über die CSS-Eigenschaft \"font-weight\" definiert ist, kann dieselbe Bezeichnung für jeden Schriftschnitt verwendet werden.",
                "light": "Light",
                "normal": "Normaler Schriftschnitt",
                "title": "Schriftbezeichung für \"{font}\""
              },
              "title": "Schriftbezeichnung"
            },
            "title": "Einstellungen"
          },
          "title": "Typografie",
          "types": {
            "customFonts": "Benutzerdefinierte Schriften",
            "sansserif": "Serifenlose Schriften",
            "serif": "Serife Schriften"
          }
        }
      },
      "create": {
        "title": "Neues Widget erstellen"
      },
      "deleteDialog": {
        "message": "Soll das Widget wirklich gelöscht werden?<br>Diese Aktion kann nicht wieder rückgängig gemacht werden. Falls dieses Widget in einer Website eingebaut wurde, wird es dort nicht mehr funktionieren.",
        "title": "Widget wirklich löschen?"
      },
      "description": "Definieren Sie die Stile der Widgets und Gutscheinvorlagen. Anschließend können Sie den HTML-Code generieren, um die Widgets auf Ihrer Website einzubetten.",
      "edit": {
        "title": "Widget editieren"
      },
      "planUpgrade": {
        "message": "Sie haben die maximale Anzahl zulässiger Widgets erreicht.<br/>Upgraden Sie Ihre Gutschein-App-Lizenz auf <i>Professional</i>, um weitere Widgets anlegen zu können."
      },
      "template": {
        "actions": {
          "setAsDefault": "Als Standard setzen"
        },
        "edit": {
          "title": "Gutscheinvorlage konfigurieren"
        },
        "isDefault": "Standard",
        "layouts": {
          "landscape": "Querformat",
          "portrait": "Hochformat"
        },
        "types": {
          "custom-slant": "{orgName}",
          "custom-square": "{orgName}",
          "simple": "Minimalistisch",
          "slant": "Schräg",
          "square": "Quadrat"
        },
        "typography": {
          "h1": {
            "description": "",
            "title": "Überschrift"
          },
          "h2": {
            "description": "",
            "title": "Überschrift"
          },
          "label": {
            "description": "",
            "title": "Inklusivleistungen und Footer"
          },
          "p": {
            "description": "",
            "title": "Grußbotschaft"
          },
          "small": {
            "description": "",
            "title": "Inklusivleistungen"
          }
        }
      },
      "title": "Widgets",
      "v1": {
        "badge": "Keine Konfiguration möglich",
        "message": "Sie besitzen eine veraltete Version des Widgets, welche die erweiterte Konfiguration leider nicht unterstützt.",
        "title": "Sie besitzen eine alte Version des Widgets."
      },
      "widget": {
        "properties": {
          "description": "Beschreibung",
          "name": "Name"
        }
      }
    }
  }], ["en", {
    "aeConfiguration": {
      "apps": {
        "accommodations": {
          "accommodation": "Accommodation",
          "accommodationText": "Accommodation Name",
          "addAccommodation": "Add accommodation",
          "dialog": {
            "title": "Accommodation Channel"
          }
        },
        "journal": {
          "dialog": {
            "empty": "There are no posts available.",
            "error": "An error occurred while loading the posts.",
            "title": "Choose post"
          }
        },
        "offer": {
          "dialog": {
            "empty": "There are no offers available.",
            "error": "An error occurred while loading the offers.",
            "title": "Choose offer"
          }
        },
        "survey": {
          "dialog": {
            "empty": "There are no surveys available.",
            "error": "An error occurred while loading the surveys.",
            "title": "Choose survey"
          }
        },
        "voucher": {
          "dialog": {
            "empty": "There are no vouchers available.",
            "error": "An error occurred while loading the vouchers.",
            "expirationFormats": {
              "end_of_next_year": "End of next year",
              "end_of_year": "End of year",
              "five_years": "5 years",
              "indefinitely": "Unlimited",
              "one_year": "1 year",
              "three_years": "3 years",
              "three_years_end_of_year": "End of the third year",
              "two_years": "2 years"
            },
            "openVoucherApp": "Create new voucher in A+ VOUCHERS",
            "title": "Choose voucher",
            "voucherProperties": {
              "validityPeriod": "Validity",
              "value": "Value"
            },
            "voucherTypes": {
              "dynamic": "Custom",
              "gift": "Incentive Voucher",
              "product": "Product Voucher",
              "service": "Service Voucher",
              "stay": "Accommodation Voucher",
              "treatment": "Treatment Voucher",
              "value": "Value Voucher"
            }
          }
        },
        "voucherWidget": {
          "dialog": {
            "empty": "There are no voucher widgets available.",
            "error": "An error occurred while loading the voucher widgets.",
            "title": "Choose voucher widget"
          }
        }
      },
      "content": {
        "allCategories": "All categories",
        "allContents": "All contents",
        "expired": "Expired",
        "selectCategory": "Choose category",
        "selectContent": "Choose content"
      },
      "dateRanges": {
        "addDateRange": "Add date range",
        "dateRange": "Date range"
      },
      "images": {
        "addImage": "Add image",
        "imageTitle": "Image {count}"
      },
      "item": {
        "addFile": "Add file",
        "addText": "Add text",
        "addUrl": "Link configuration",
        "file": "File",
        "helpTexts": {
          "inheritanceDescriptionStyle": "If this icon is enabled (blue), the data is synchronized with that of your organization (account). This allows you to manage the data centrally in your organization for all apps.",
          "inheritanceDescriptionTemplate": "If this icon is enabled (blue), the data is synchronized with that of the style. This allows you to manage the data centrally in the style for all campaigns and templates."
        },
        "language": "Language",
        "multiSelectPlaceholder": "Choose options",
        "noLink": "No link",
        "survey": "Survey",
        "surveyLink": "Link to survey",
        "textDialogTitle": "Insert text",
        "type": "Type",
        "url": "URL",
        "urlDialogTitle": "Insert url"
      },
      "validations": {
        "accommodations": {
          "index": {
            "channelCode": "Channel is required.",
            "required": "Text is required."
          },
          "minCount": "At least {minCount, plural, =1 {1 accommodation} other {# accommodations}} must be selected."
        },
        "general": {
          "expiredContent": "This content is expired.",
          "index": {
            "required": "This value is required.",
            "textPlaceholder": "The text contains invalid placeholders.",
            "url": "The link is invalid."
          },
          "maxValue": "The value must be less or equal to {maxValue}.",
          "minValue": "The value must be greater or equal to {minValue}.",
          "noContent": "The selection has no published contents.",
          "placeholder": "This value contains invalid placeholders.",
          "required": "This value is required.",
          "text": {
            "placeholder": "The text contains invalid placeholders.",
            "required": "The text is required."
          },
          "unpublishedContent": "This content is not published.",
          "url": {
            "placeholder": "The link contains invalid placeholders.",
            "required": "The link is required.",
            "url": "The link is invalid."
          }
        },
        "images": {
          "index": {
            "required": "An image must be set.",
            "url": "The link is invalid."
          },
          "required": "Add at least one image."
        },
        "multimedia": {
          "file": "Only a file can be added.",
          "image": "Only an image can be added.",
          "images": "Only images can be added.",
          "medium": "Only an image or a Youtube video can be added."
        }
      }
    },
    "aiButton": {
      "loadingProgress": "Generating..."
    },
    "app": {
      "title": "ADDITIVE+ VOUCHERS"
    },
    "appNames": {
      "1": {
        "name": "ACCOUNT",
        "presentationName": "ADDITIVE+ ACCOUNT"
      },
      "10": {
        "name": "MARKETING DASHBOARD",
        "presentationName": "ADDITIVE+ MARKETING DASHBOARD"
      },
      "100": {
        "name": "CMS",
        "presentationName": "ADDITIVE+ CMS"
      },
      "11": {
        "name": "INQUIRIES",
        "presentationName": "ADDITIVE+ INQUIRIES"
      },
      "12": {
        "name": "CRM",
        "presentationName": "ADDITIVE+ CRM"
      },
      "2": {
        "name": "VOUCHERS",
        "presentationName": "ADDITIVE+ VOUCHERS"
      },
      "4": {
        "name": "CONTENTS",
        "presentationName": "ADDITIVE+ CONTENTS"
      },
      "5": {
        "name": "MULTIMEDIA",
        "presentationName": "ADDITIVE+ MULTIMEDIA"
      },
      "50": {
        "name": "MARKETING AUTOMATION",
        "presentationName": "ADDITIVE+ MARKETING AUTOMATION"
      },
      "51": {
        "name": "MARKETING INSIGHTS",
        "presentationName": "ADDITIVE+ MARKETING INSIGHTS"
      },
      "6": {
        "name": "MAILER",
        "presentationName": "ADDITIVE+ MAILER"
      },
      "7": {
        "name": "NEWSLETTER",
        "presentationName": "ADDITIVE+ NEWSLETTER"
      },
      "8": {
        "name": "GUEST INTRANET",
        "presentationName": "ADDITIVE+ GUEST INTRANET"
      },
      "9": {
        "name": "JOURNAL",
        "presentationName": "ADDITIVE+ JOURNAL"
      }
    },
    "auditLog": {
      "deletedUser": "Deleted User",
      "description": "Find the chronological list of all your users' activities within the app below. Please note that the users must be part of your organization for you to see their activities.",
      "empty": "No activities available",
      "entries": "Entries",
      "page": "Page",
      "time": "{time}",
      "title": "Activity Log"
    },
    "awColorsMenu": {
      "accent": "Accent color",
      "alpha": "Alpha",
      "ambient": "Ambient color",
      "custom": "Custom",
      "dialog": {
        "color": {
          "label": "Color"
        },
        "contrastColor": {
          "label": "Contrast color"
        }
      },
      "main": "Main color"
    },
    "awTypographyDialog": {
      "button": {
        "description": "",
        "title": "Buttons"
      },
      "desktop": "Desktop",
      "enableUppercase": "Enable uppercase",
      "fontColor": "Font color",
      "fontFamily": "Font family",
      "fontOptions": {
        "default": "Standard",
        "sansserif": "Sans serif",
        "serif": "Serif"
      },
      "fontSize": "Font size",
      "h1": {
        "description": "",
        "title": "Headings"
      },
      "h2": {
        "description": "",
        "title": "Subheadings"
      },
      "h3": {
        "description": "",
        "title": "Other subheadings"
      },
      "label": {
        "description": "",
        "title": "Labels"
      },
      "letterSpacing": "Letter spacing",
      "letterSpacingUnit": "em",
      "lineHeight": "Line height",
      "mobile": "Mobile",
      "p": {
        "description": "",
        "title": "Text"
      },
      "small": {
        "description": "",
        "title": "Text (small)"
      },
      "types": {
        "customFonts": "Custom font families",
        "sansserif": "Sans serif fonts",
        "serif": "Serif fonts"
      },
      "uppercase": "Uppercase"
    },
    "awTypographyList": {
      "item": {
        "corporateDesignTypography": {
          "button": "Button",
          "h1": "Heading 1",
          "h2": "Heading 2",
          "h3": "Heading 3",
          "label": "Label",
          "p": "Paragraph",
          "small": "Small"
        },
        "warning": "Custom font families are not displayed in the preview."
      },
      "properties": {
        "button": {
          "description": "",
          "title": "Buttons"
        },
        "h1": {
          "description": "",
          "title": "Main headings"
        },
        "h2": {
          "description": "",
          "title": "Subheadings"
        },
        "h3": {
          "description": "",
          "title": "Other subheadings"
        },
        "label": {
          "description": "",
          "title": "Labels"
        },
        "p": {
          "description": "",
          "title": "Text"
        },
        "small": {
          "description": "",
          "title": "Text (small)"
        }
      }
    },
    "awTypographySettings": {
      "embedFonts": {
        "description": "When this option is enabled, all used fonts will be embedded in the widget. This can lead to longer loading times.",
        "title": "Embed fonts"
      },
      "fontName": {
        "dialog": {
          "bold": "Bold",
          "confirm": "Confirm",
          "description": "The names of the various fonts can be defined below. Please be aware that the selected name must correspond with the name used on the website. Should the font width be defined by the CSS property \"font-weight\" rather than by individual names, you may use the same name for each font style.",
          "light": "Light",
          "normal": "Regular",
          "title": "Font name for \"{font}\""
        },
        "title": "Font name"
      }
    },
    "breadcrumbs": {
      "demo": {
        "views": {
          "routeName": "Views",
          "simple-breadcrumb": {
            "routeName": "simple-breadcrumb"
          }
        }
      },
      "docs": {
        "components": {
          "routeName": "Components",
          "ui-breadcrumbs": {
            "routeName": "UI-Breadcrumbs"
          },
          "ui-button": {
            "routeName": "Buttons"
          },
          "ui-chart": {
            "routeName": "Charts"
          }
        },
        "routeName": "Docs"
      },
      "instance": {
        "widgets": {
          "routeName": "Widgets",
          "templates": {
            "routeName": "Templates",
            "template": {
              "routeName": "Template"
            }
          },
          "widget": {
            "routeName": "Widget"
          }
        }
      }
    },
    "colorsMenu": {
      "accent": "Accent Color",
      "ambient": "Ambient Color",
      "main": "Main Color",
      "standard": "Default Color"
    },
    "components": {
      "av-customer-form-modal": {
        "isCompany": "Buy as company",
        "otherData": "Other contact data",
        "properties": {
          "addressCountry": "Country",
          "addressLocality": "Locality",
          "companyName": "Company Name",
          "companyRecipientCode": "Recipient Code",
          "email": "Email Address *",
          "familyName": "Last Name *",
          "gender": "Gender *",
          "genders": {
            "female": "Female",
            "male": "Male",
            "none": "Not specified"
          },
          "givenName": "First Name *",
          "noCountry": "Not specified",
          "postalCode": "Zip Code",
          "streetAddress": "Street",
          "telephone": "Phone",
          "vat": "VAT no."
        },
        "title": "Contact Details"
      },
      "av-package-detail-modal": {
        "description": "The gift option will be displayed in the widget and can be selected by the buyer.",
        "errors": {
          "inactiveShipping": {
            "description": "{count, plural, =1 {One} other {#}} selected {count, plural, =1 {shipping option} other {shipping options}} ({inactiveShippings}) {count, plural, =1 {is} other {are}} not available or {count, plural, =1 {does not} other {do not}} exist.",
            "title": "Warning!"
          }
        },
        "properties": {
          "description": "Description",
          "name": "Name",
          "price": "Price",
          "shipping": "Shipping Options"
        },
        "shipping": {
          "description": "Choose one or more shipping options for which you want to enable this gift option.",
          "title": "Shipping Options"
        },
        "title": "Gift Option"
      },
      "av-package-list": {
        "title": "Gift Options"
      },
      "av-shipping-detail-modal": {
        "countryPrices": {
          "addPriceVariant": "Add pricing",
          "description": "Define the shipping costs for this shipping option per country.",
          "title": "Countries & Prices"
        },
        "description": "The shipping options will be displayed in the widget and can be selected by the buyer.",
        "properties": {
          "countries": "Countries",
          "description": "Description",
          "name": "Name",
          "price": "Price",
          "shipping": "Shipping Options"
        },
        "title": "Shipping Option"
      },
      "avWidgetV1Notification": {
        "buttonText": "Go to widgets",
        "defaultText": "You are currently using an older version of the widget which does not support incentive vouchers. Ask your website provider to embed the new widget version on your website to enable this feature. The corresponding codes can be found in the menu item \"Widgets & Templates\"."
      },
      "content-info": {
        "showLess": "Show less",
        "showMore": "Show more"
      },
      "sync-dialog": {
        "actions": {
          "sendEmail": "Send instructions"
        },
        "asa": {
          "description": "Please enter the email address of your ASA service provider. After clicking on \"send instructions\" the provider will receive the necessary access information and the instruction for setting up the synchronization.",
          "title": "ASA Synchronization"
        },
        "credentials": {
          "email": "Email"
        },
        "descriptionAlreadySynced": "<b>Warning!</b><br>The instruction has already been sent. If it is transmitted again, a new password will be created, which must be used for the setup process (the previously generated password will no longer work).",
        "hgv": {
          "description": "Please enter the email address of your HGV booking widget service provider. After clicking on \"send instructions\" the provider will receive the necessary access information and the instruction for setting up the integration.",
          "title": "HGV Booking Widget Integration"
        }
      }
    },
    "dashboard": {
      "charts": {
        "comparison": {
          "last_year": "compared to last year"
        },
        "labels": {
          "ordered": "Ordered",
          "ordered_amount": "Ordered amount",
          "paid": "Paid",
          "paid_amount": "Paid amount",
          "redeemed": "Redeemed",
          "redeemed_amount": "Redeemed amount"
        },
        "orderedAmountChart": {
          "description": "The following chart shows the total amount of ordered, paid and redeemed vouchers {year, select, last_12_months{in the last 12 months} other {in {year}}}.",
          "title": "Amount"
        },
        "ordersChart": {
          "description": "The following chart shows the number of ordered, paid and redeemed vouchers {year, select, last_12_months{in the last 12 months} other {in {year}}}.",
          "title": "Orders"
        }
      },
      "description": "The following key figures provide an overview of the number and amount of voucher orders in the current year.",
      "keyIndicators": {
        "amountSumMonthly": {
          "description": "Purchase amount (sales revenue) of ordered vouchers in the current month (gift and shipping options included)",
          "name": "Purchase amount in {month} {year}"
        },
        "amountSumYearly": {
          "description": "Purchase amount (sales revenue) of ordered vouchers (gift and shipping options included)",
          "name": "Purchase amount in {period, select, last_12_months{the last 12 months} other {{year}}}"
        },
        "ordersCountMonthly": {
          "description": "Number of ordered vouchers in the current month (cancellations and incentive vouchers not included)",
          "name": "Orders in {month} {year}"
        },
        "ordersCountYearly": {
          "description": "Number of ordered vouchers (cancellations and incentive vouchers not included)",
          "name": "Orders in {period, select, last_12_months{the last 12 months} other {{year}}}"
        }
      },
      "lastOrders": {
        "columns": {
          "identifier": "Order Number",
          "name": "Name",
          "orderedAt": "Order Date",
          "state": "Order Status",
          "voucher": "Voucher"
        },
        "description": "This table shows the last 10 orders that have been received or whose status has changed.",
        "filter": {
          "empty": "Your search did not return any results.",
          "label": "Filters"
        },
        "popover": "Show order",
        "title": "Last Orders"
      },
      "newOrderDialog": {
        "dynamicVoucherDialog": {
          "properties": {
            "amount": "Value *",
            "name": "Name *",
            "services": "Conditions / Services *",
            "validityPeriod": "Validity *",
            "validityPeriodCustomDate": "Custom validity date *"
          },
          "title": "Create voucher"
        },
        "multimedia": {
          "imageWarning": "Only images may be selected."
        },
        "order": {
          "actionLabels": {
            "customer": "Add contact information",
            "language": "Choose language",
            "layout": "Choose layout",
            "note": "Add comment",
            "orderVoucher": "Order voucher",
            "paymentType": "Choose payment method",
            "preview": "Show preview",
            "text": "Add a message",
            "title": "Add salutation",
            "value": "Add value"
          },
          "help": {
            "gift": "If this option is enabled, the voucher will be sent as a gift to the email address specified under \"contact information\"."
          },
          "labels": {
            "customer": "Contact information",
            "gift": "Incentive voucher",
            "language": "Language",
            "layout": "Layout",
            "note": "Comment",
            "paymentType": "Payment method",
            "preview": "Preview",
            "text": "Message",
            "title": "Salutation",
            "value": "Voucher value"
          },
          "placeholders": {
            "note": "Dairy allergy",
            "paymentType": "Choose payment method",
            "title": "Dear Max"
          },
          "warnings": {
            "recommendedLength": "Value exceeds the recommended length of {recommendedLength} characters."
          }
        },
        "orderElementVoucher": {
          "actionLabel": "Choose voucher",
          "discountedPrice": "Discount price",
          "label": "Voucher *",
          "validUntil": "Valid until",
          "validity": "Validity",
          "voucherAmount": "Voucher value"
        },
        "orderTotalAmount": {
          "label": "Total"
        },
        "orderVoucher": "Order your voucher",
        "paymentTypesError": "You must first enable one of the following payment methods: \"bank transfer\" or \"payment on site\"",
        "productSelectDialog": {
          "actionLabel": "Add a product",
          "errorMessage": "Please choose at least one product.",
          "label": "Product *",
          "minutes": "{count, plural, =1 {1 minute} other {# minutes}}",
          "selectAction": "Choose products"
        },
        "roomSelectDialog": {
          "actionLabel": "Choose room",
          "fixedPersonCount": "This voucher is limited to a fixed number of people.",
          "label": "Room *",
          "roomTypes": {
            "apartment": "Apartment ",
            "chalet": "Chalet",
            "double": "Double room",
            "family": "Family room",
            "juniorSuite": "Junior suite",
            "single": "Single room",
            "suite": "Suite"
          },
          "selectAction": "Choose room"
        },
        "title": "New Order",
        "treatmentSelectDialog": {
          "actionLabel": "Add a treatment",
          "errorMessage": "Please choose at least one treatment.",
          "label": "Treatment *",
          "minutes": "{count, plural, =1 {1 minute} other {# minutes}}",
          "selectAction": "Choose treatments"
        },
        "unexpectedErrorDialog": {
          "description": "An unexpected error occurred. Please try again later.",
          "title": "Unexpected error"
        },
        "validationErrorDialog": {
          "description": "Please fill in all required fields.",
          "title": "The form is not complete"
        },
        "voucherSelectDialog": {
          "amountWithDiscount": "-- {discount} <span class='red line-through'>{amount}</span> ",
          "amountWithIncentive": "-- Free <span class='red line-through'>{amount}</span> ",
          "amountWithoutDiscount": "-- {amount}",
          "category": "Voucher category",
          "empty": "There are no published vouchers in this language yet.",
          "emptyCategory": "There are no published vouchers for this category yet.",
          "noVouchersError": "There are no published vouchers yet.",
          "title": "Choose voucher"
        }
      },
      "periodSelection": {
        "label": "Observation Period",
        "last_12_months": "Last 12 months"
      },
      "salutations": {
        "afternoon": "Good afternoon",
        "evening": "Good evening",
        "morning": "Good morning"
      },
      "title": "Dashboard"
    },
    "dialogService": {
      "conflictError": {
        "dependencies": {
          "contentTypes": {
            "appIntegrations": "ADDITIVE+ APPS integrations",
            "campaigns": "Campaigns",
            "comments": "Comments",
            "landingPages": "Landing pages",
            "leadIntegrations": "Lead integrations",
            "pmsIntegrations": "PMS integrations",
            "posts": "Posts",
            "settings": "Settings",
            "surveys": "Surveys",
            "thirdPartyTokens": "ADDITIVE+ APPS interfaces",
            "usps": "USPs",
            "widgets": "Widgets"
          },
          "headers": {
            "app": "App",
            "content": "Content",
            "contentType": "Content Type"
          }
        },
        "description": "The object can not be deleted, because it is used by other contents. Make sure to resolve the remaining dependencies in order to delete this object.",
        "title": "The object is in use"
      },
      "discardChanges": {
        "continueEditing": "Continue editing",
        "discardAction": "Discard",
        "message": "Do you want to discard the unsaved changes?",
        "title": "Attention!"
      },
      "error": {
        "message": "An unexpected error has occurred. Please try again later.",
        "title": "Something went wrong"
      },
      "noPermission": {
        "message": "You do not have permission to perform this action. Please contact your administrator.",
        "title": "Missing permission"
      }
    },
    "errors": {
      "amountFormat": "Invalid format",
      "beforeDate": "The validity date must be in the future.",
      "between": "Must be between {min} and {max} ",
      "date": "The entered value is not a valid date.",
      "desktopOnly": {
        "template": "The voucher template cannot be edited or customized on mobile devices.",
        "title": "Warning!",
        "widget": "The widget cannot be edited or customized on mobile devices."
      },
      "discountPeriod": "The end date must come after the start date.",
      "discountedAmount": "The entered value must be less than the voucher value.",
      "email": "The entered value is not a valid email address.",
      "greaterZero": "The entered value must be greater than 0.",
      "images": "A maximum of 10 images can be added.",
      "invalidDateRange": "Invalid period",
      "invalidRedeemCode": "Invalid voucher code",
      "invalidUrl": "Invalid URL",
      "invalidWidgetName": "The widget name contains invalid characters.",
      "maxAmount": "Must not be less than the voucher value",
      "maxLength": "The entered value must not exceed the length of {maxLength} characters.",
      "minAmount": "Must not exceed the voucher value",
      "mustStartWithLetter": "The widget name must start with a letter.",
      "number": "The entered value is not a valid number.",
      "productCategories": "You have to select at least one product category.",
      "required": "The value must not be empty.",
      "roomTypes": "You have to select at least one room type.",
      "treatmentCategories": "You have to select at least one treatment category.",
      "unlockCode": {
        "format": "Only capital letters, numbers and hyphens \"-\" are allowed",
        "gift": "This setting must be enabled in case of incentive vouchers.",
        "length": "The activation code must be between {min} and {max} characters long.",
        "prefix": "Only capital letters and a maximum of 4 characters are allowed",
        "unique": "This activation code is already in use."
      }
    },
    "faq": {
      "settings": {
        "inheritedSettings": {
          "answer": "Certain settings can be \"inherited\" from your ADDITIVE+ ACCOUNT, such as email address or phone number. When the DNA icon is enabled, transmission is activated and the corresponding values from your ADDITIVE+ ACCOUNT will be used automatically. However, if you need to set your own value for a particular ADDITIVE+ VOUCHERS field, such as a different email address, you can disable the DNA icon and set a new value.",
          "question": "What does the DNA symbol<img class=\"dib\" style=\"vertical-align: middle;\" width=\"19\" height=\"19\" src=\"/images/dna.svg\"/>mean?"
        },
        "paymentTypesAmount": {
          "answer": "The number of available payment methods is limited. The maximum number available varies depending on your subscription plan. If you need additional payment methods, upgrade your ADDITIVE+ VOUCHERS plan within your ADDITIVE+ ACCOUNT.",
          "question": "Why can't I add a payment method?"
        }
      },
      "title": "Frequently Asked Questions",
      "voucher": {
        "createVoucher": {
          "answer": "Click the \"+\" button at the bottom right corner of your screen and create the voucher that fits your needs.",
          "question": "How do I create a voucher?"
        },
        "publishVoucher": {
          "answer": "To be displayed in the widget, the voucher must first be published. To do so, click on the voucher in the voucher list. Then click on the symbol <img class=\"dib\" style=\"vertical-align: middle;\" width=\"19\" height=\"19\" src=\"/images/publish.svg\"/> in the upper right corner of the screen. A dialog window will open that allows you to choose the language in which the voucher will be published.",
          "question": "Why is the voucher not displayed in the widget?"
        },
        "voucherInhouse": {
          "answer": "In the menu item \"Dashboard\", a new voucher order can be placed by clicking the \"+\" button at the bottom right corner of the screen.",
          "question": "I would like to create a personal voucher for a customer. How can I do that?"
        },
        "voucherSort": {
          "answer": "Vouchers are sorted according to two criteria: the date of creation and the number of stars. The more stars a voucher has, the further ahead it is displayed. If you want to highlight one of the vouchers, you can do so by increasing the number of stars.",
          "question": "How can I sort my vouchers?"
        },
        "voucherTemplate": {
          "answer": "Navigate to the tab \"Voucher Templates\" within the menu item \"Widgets & Templates\" and choose from a selection of available templates. A simple click on one of the templates allows you to view and edit it. Set the template as your default, once you have found the right one.",
          "question": "Where can I edit the voucher template?"
        },
        "voucherTypes": {
          "answer": "There are 4 different voucher types:<ul><li class='pt1'><b>Value Vouchers:</b> Value vouchers are classic vouchers and have a certain value. When creating a value voucher, the minimum and maximum value can be set.</li><li class='pt1'><b>Service Vouchers:</b> Service vouchers can be redeemed for specific goods or services.</li><li class='pt1'><b>Treatment Vouchers:</b> This voucher type is only available starting from the \"Professional\" plan and can be redeemed for treatments. All offered treatments must be centrally managed in ADDITIVE+ CONTENTS.</li><li class='pt1'><b>Accommodation Vouchers:</b> This voucher type is only available starting from the \"Professional\" plan. With an overnight stay voucher your customers can purchase an overnight stay for any number of people in any room. All rooms must be centrally managed in ADDITIVE+ CONTENTS.</li></ul>",
          "question": "What types of vouchers are there?"
        },
        "voucherUnlock": {
          "answer": "You can hide a voucher by using an activation code. If enabled, the voucher will not be displayed in the widget. Instead, only users who enter the predefined activation code into the corresponding field will be able to see it.",
          "question": "What is an activation code?"
        },
        "voucherWidget": {
          "answer": "In the menu item \"Widgets & Templates\" you will find a widget that we have created for you. Edit the widget according to your preferences and ask your website provider to embed it on your website. The corresponding HTML codes can be found in the widget editor. Even after its installation on your website, the widget will take your graphic adaptations into account.",
          "question": "How can I add my vouchers to my website?"
        }
      }
    },
    "font": {
      "sansSerif": "Sans serif",
      "serif": "Serif",
      "standard": "Standard"
    },
    "global": {
      "actions": {
        "activate": "Enable",
        "addImage": "Add an image",
        "code": "Code",
        "deactivate": "Disable",
        "delete": "Delete",
        "download": "Download",
        "edit": "Edit",
        "help": "Help",
        "logout": "Sign out",
        "persist": "Apply",
        "preview": "Preview",
        "publish": "Publish",
        "reactivate": "Reactivate",
        "rename": "Rename",
        "retry": "Retry",
        "save": "Save",
        "select": "Select",
        "show": "Show"
      },
      "all": "All",
      "delete": {
        "description": "The element will be irrevocably deleted and can no longer be restored.<br/>Are you sure you want to delete <b>\"{name}\"</b>?",
        "title": "Warning!"
      },
      "deleteCategory": {
        "description": "The category will be irrevocably deleted and can no longer be restored.<br/>Are you sure you want to delete <b>{name}</b>?",
        "title": "Warning!"
      },
      "deleteWidget": {
        "conflictError": {
          "description": "The widget cannot be deleted as it is still being used by other contents. Make sure to resolve the remaining dependencies in order to delete this widget.",
          "title": "The widget is still in use"
        },
        "description": "The widget will be irrevocably deleted and can no longer be restored. If the widget is currently embedded on your website, it will no longer work.",
        "title": "Warning!"
      },
      "discardChanges": {
        "action": "Discard",
        "description": "Do you want to delete unsaved changes?",
        "title": "Warning!"
      },
      "error": "Error",
      "errors": {
        "lower_than_min_stays": {
          "message": "The voucher order cannot be created because the selected stays are shorter than the minimum stay duration of the room.",
          "title": "Warning!"
        },
        "public_voucher_left": {
          "message": "This category cannot be deleted because it contains published vouchers.",
          "title": "Warning!"
        },
        "publish": {
          "category_id": {
            "missing_translation": "The translation for this category is missing."
          },
          "discounted_amount": {
            "validation": {
              "absent_if": "Discounted amount must not be set without active discount."
            }
          },
          "has_discount_period": {
            "false_if_not_discounted": "Discount period must not be set without active discount."
          },
          "product_categories": {
            "not_exists": "One or more products do not exist in this language."
          },
          "room_types": {
            "not_exists": "One or more rooms do not exist in this language."
          },
          "services": {
            "validation": {
              "required": "The service description in this language is missing.",
              "string": "The service description in this language is missing."
            }
          },
          "treatment_categories": {
            "not_exists": "One or more treatments do not exist in this language."
          }
        },
        "required": "Value must not be empty",
        "url": "Value must be a valid URL"
      },
      "multimedia": {
        "imageWarning": "Only images can be selected."
      },
      "paymentTypes": {
        "bank_transfer": "Bank transfer",
        "cash": "Payment on site",
        "concardis": "Concardis",
        "gastro_pool": "gastropool (old version)",
        "gastro_pool_v3": "gastropool",
        "hobex": "hobex",
        "hogast": "hogast (old version)",
        "hogast_v3": "hogast",
        "klarna": "Klarna",
        "nexi": "Nexi",
        "online": "Payment through website",
        "oppwa": "hobex",
        "outstanding": "Payment pending",
        "paypal": "PayPal",
        "sia_vpos": "SIA VPOS",
        "six_payments": "SIX Payment Services",
        "stripe": "Stripe",
        "vpos": "Virtual-POS"
      },
      "toasts": {
        "update": {
          "actionLabel": "Reload",
          "description": "Unsaved changes will be lost.",
          "title": "Update available"
        }
      }
    },
    "help": {
      "vouchers": {
        "sort": "Vouchers with a high star rating are listed first in the widget.<br>If different vouchers have the same number of stars, the voucher that was edited last is displayed first."
      }
    },
    "languageSelect": {
      "add": "Add language",
      "ar": "Arabic",
      "de": "German",
      "en": "English",
      "fr": "French",
      "it": "Italian",
      "nl": "Dutch",
      "pl": "Polish",
      "remove": "Remove language",
      "removeDescription": "Do you really want to remove the language {language}?",
      "ru": "Russian"
    },
    "mmAttributesDialog": {
      "alt": "Alt attribute",
      "dialogTitle": "Image attribute",
      "helpText": "The title tag of an image is the text that is displayed when the mouse moves over the image. The alt attribute is used by search engines to classify the content of the image and may also be displayed if the image cannot be loaded.",
      "save": "Save",
      "title": "Title"
    },
    "mmBulkDownload": {
      "confirm": {
        "description": "Do you want to download this folder? The download link will be sent to you via email.",
        "title": "Download folder"
      },
      "download": "Download",
      "error": {
        "description": "The download of the selected medium is not supported.",
        "title": "Warning!"
      },
      "success": {
        "description": "The download link will be sent to your email address.",
        "title": "Download successfully started"
      }
    },
    "mmCropperView": {
      "save": "Save"
    },
    "mmExternalSearch": {
      "duration": {
        "hours": "{count, plural, =1 {# hour} other {# hours}}",
        "minutes": "{count, plural, =1 {# minute} other {# minutes}}",
        "seconds": "{count, plural, =1 {# second} other {# seconds}}"
      },
      "insertSearchParam": "Enter a search term",
      "noResults": "No results found",
      "provider": {
        "unsplash": {
          "count": "{count, plural, =1 {1 image} other {# images}}",
          "description": "Enter a search term here to find images on Unsplash.",
          "name": "Unsplash",
          "save": "Add images",
          "title": "Add Unsplash images"
        },
        "youtube": {
          "count": "{count, plural, =1 {1 video} other {# videos}}",
          "description": "Enter a search term here to find the desired YouTube video or simply enter the corresponding URL.",
          "name": "YouTube",
          "save": "Add video",
          "title": "Add YouTube video"
        }
      }
    },
    "mmImageTransforms": {
      "brightness": "Brightness",
      "contrast": "Contrast",
      "editDialog": {
        "attributes": {
          "description": "Define the description text and alternative text for the image",
          "title": "Set image attributes"
        },
        "imageTransform": {
          "description": "Use this to edit the image",
          "title": "Edit image"
        }
      },
      "imageLoadError": "An error occurred while loading the image, please try again later.",
      "saturation": "Saturation",
      "setFocusPoint": "Set focus point",
      "title": "Edit image"
    },
    "mmMediumItem": {
      "attribution": {
        "unsplash": "Photo by &nbsp;<a target=\"_blank\" rel=\"noopener\" href=\"{authorUrl}\" class=\"white\">{author}</a>&nbsp; on &nbsp;<a target=\"_blank\" rel=\"noopener\" href=\"{url}\" class=\"white\">Unsplash</a>",
        "youtube": "{title} on <u>Youtube</u>"
      },
      "download": "Download",
      "error": "The file could not be loaded",
      "more": "More"
    },
    "mmResourceGallery": {
      "isImageAddable": "Add image",
      "isImageOrVideoAddable": "Add image or video",
      "isVideoAddable": "Add video"
    },
    "multimediaEngine": {
      "actions": {
        "add": "Add",
        "bulkDelete": "Delete",
        "bulkDownload": "Download",
        "delete": "Delete",
        "move": "Move here",
        "remove": "Remove",
        "rename": "Rename",
        "retry": "Retry",
        "save": "Save"
      },
      "dialogs": {
        "confirmDeleteFolder": {
          "description": "Are you sure you want to delete this folder and its contents irrevocably?",
          "title": "Delete folder"
        },
        "confirmDownloadFolder": {
          "description": "Do you want to download this folder?  The download link will be sent to you via email.",
          "title": "Download Folder"
        },
        "confirmRemoveFolder": {
          "description": "Are you sure you want to remove this shared folder and its contents?",
          "title": "Remove shared folder"
        },
        "deleteFolderIncomplete": {
          "description": "The folder could not be deleted completely because some of its files are currently in use. In total {count, plural, =1 {# File} other {# Files}} were deleted.",
          "title": "Folder could not be deleted"
        },
        "removeFolderError": {
          "description": "The folder could not be removed because some of its files are currently in use.",
          "title": "Folder could not be removed"
        },
        "youtubeDownloadError": {
          "description": "The download of the selected medium is not supported.",
          "title": "Attention"
        }
      },
      "errors": {
        "required": "Value can't be empty"
      },
      "folderTree": {
        "root": "Multimedia"
      },
      "folderView": {
        "bulkDelete": {
          "confirmDialog": {
            "description": "Are you sure you want to delete {count, plural, =1 {this medium} other {this media}}",
            "title": "Delete {count, plural, =1 {medium} other {media}}"
          },
          "conflict": {
            "description": "One or more media cannot be deleted because they are currently used in content. To be able to delete these media, you must first remove them from all content.",
            "title": "Not all media are deletable"
          },
          "disabled": "Shared folders and files cannot be deleted.",
          "error": {
            "description": "The selected media cannot be deleted because they are currently being used in content. To be able to delete these media, you must first remove them from all content.",
            "title": "Media are in use"
          }
        },
        "bulkDownload": {
          "confirmDialog": {
            "description": "Are you sure you want to delete {count, plural, =1 {this medium} other {this media}}. The download link will be sent to you via email.",
            "title": "Download {count, plural, =1 {medium} other {media}}"
          },
          "disabled": "Shared folders and files cannot be downloaded."
        },
        "bulkMove": {
          "disabledParentFolder": "Folders cannot be moved into themselves.",
          "disabledSharedFolder": "Folders and files cannot be moved within shared folders.",
          "disabledSharedSelection": "Shared folders and files cannot be moved."
        },
        "delete": {
          "confirmDeleteMedium": {
            "description": "Are you sure you want to delete this medium irrevocably?",
            "title": "Delete medium"
          },
          "conflict": {
            "description": "The medium cannot be deleted, as it is currently used in a content. To delete this medium, you must first remove it from all contents.",
            "title": "Medium is in use"
          }
        },
        "folder": "Folder",
        "media": "Files",
        "new": {
          "addSharedFolder": {
            "title": "Add shared folder"
          },
          "externalSearch": {
            "title": "External media"
          },
          "folder": {
            "title": "Create folder"
          },
          "title": "Add",
          "upload": {
            "title": "Upload file"
          }
        },
        "sharedFolder": "Shared folder",
        "sharedMedia": "Shared files",
        "uploading": {
          "description": "While an upload is running, nothing else can be uploaded.",
          "title": "Uploading..."
        }
      },
      "mmAddSharedFolderDialog": {
        "errors": {
          "add": "An error occurred while adding the shared folders. Please try again.",
          "fetch": "An error occurred while loading the shared folders. Please try again."
        },
        "title": "Add shared folder"
      },
      "mmFileUpload": {
        "error": {
          "title": "Upload error"
        },
        "maxConcurrentFilesUploaded": {
          "description": "You can upload at most {count} files during a single upload. You can upload the rest after the current upload process is finished.",
          "title": "Limit for simultaneous upload reached"
        },
        "maxSizeDialog": {
          "description": "One or more files you want to upload exceed the maximum size of 10MB, they will not be uploaded.",
          "title": "Exceeding maximum size"
        }
      },
      "mmFileUploadProgress": {
        "aborted": {
          "title": "Uploading cancelled"
        },
        "errorDialog": {
          "description": "{failed} of {count} could not be uploaded, because they are either not supported or an unexpected error has occurred.",
          "title": "Upload error"
        },
        "progress": {
          "description": "Files are uploaded",
          "title": "Uploading"
        },
        "success": {
          "title": "Uploading successful"
        }
      },
      "mmFilter": {
        "mr": "Greater than",
        "noMatches": "Your filtering did not yield any resuts",
        "searchPlaceholder": "Search...",
        "type": {
          "title": "File type",
          "types": {
            "file": "File",
            "image": "Image",
            "video": "Video"
          }
        }
      },
      "mmFolderCreateEdit": {
        "name": "Name",
        "title": {
          "folder": {
            "create": "Create folder",
            "edit": "Rename folder"
          },
          "sharedFolder": {
            "create": "Create shared folder",
            "edit": "Rename shared folder"
          }
        }
      },
      "toasts": {
        "bulkDownloadSuccess": {
          "description": "The download link will be sent to your email address",
          "title": "Download sucessfully started"
        },
        "folderDeleteSuccess": {
          "description": "The folder was successfully deleted",
          "title": "Folder deleted"
        },
        "success": {
          "title": "Action performed successfully"
        }
      },
      "warnings": {
        "maxCount": "Only {count, plural, =1 {# element} other {# elements}} can be selected.",
        "recommendedSize": {
          "action": "Add anyway",
          "message": "In your selection there are files that do not meet the recommended minimum size.",
          "title": "Attention"
        }
      }
    },
    "onboarding": {
      "1": {
        "description": "Easily give your customers even more ways to buy and gift your products and services with ADDITIVE+ VOUCHERS.",
        "title": "Ready to tap into new revenue potential?"
      },
      "2": {
        "description": "A good integration and linking of your voucher system on your website increases the visibility of your offering and therefore the number of sales.",
        "title": "Automated Sales on your Website"
      },
      "3": {
        "description": "Sell vouchers directly at your \"point of sale\" such as at the reception, in the restaurant or at the cash register. The use of existing templates makes processing both easy and efficient.",
        "title": "On-site Sales"
      },
      "4": {
        "description": "Free or discounted vouchers as well as individual vouchers for certain groups of guests and customers are ideal tools for successful marketing and sales promotion.",
        "title": "Utilize your Marketing Potential"
      },
      "actions": {
        "back": "Back",
        "go": "Get started now",
        "next": "Continue",
        "skip": "Skip"
      }
    },
    "orders": {
      "cancelOrder": {
        "action": "Cancel",
        "description": "Are you sure you want to cancel <span class=\"font-medium\">\"{name}\"</span>?",
        "title": "Warning!"
      },
      "customer": {
        "address": "Address",
        "buyer": "Buyer",
        "company": {
          "companyRecipientCode": "Recipient code",
          "name": "Company",
          "vatId": "VAT no."
        },
        "email": "Email address",
        "gift": "Gift option",
        "shipping": {
          "address": "Shipping address",
          "name": "Shipping option",
          "title": "Shipping and Gift Options"
        },
        "telephone": "Phone Number",
        "title": "Contact Details"
      },
      "deleteOrder": {
        "description": "The order and all associated data will be irrevocably deleted and can no longer be restored.<br>Are you sure you want to delete <span class=\"font-medium\">\"{name}\"</span>?",
        "title": "Warning!"
      },
      "description": "Here you can find your orders in descending chronological order.",
      "export": {
        "close": "Close",
        "content": "The download of your orders is being prepared.<br/>The CSV file with the list of orders will be shortly sent to the email address <b>{email}</b>.",
        "title": "Your download is being prepared"
      },
      "order": {
        "actions": {
          "canceled": "Cancel",
          "confirm_order": "Resend confirmation",
          "confirm_payment": "Resend voucher",
          "downloadAsPDF": "Download PDF",
          "extendExpiration": "Extend voucher validity",
          "issued": "Generate voucher",
          "paid": "Mark as paid",
          "redeemVoucher": "Redeem voucher",
          "remind_payment": "Payment reminder"
        },
        "detailNavbar": {
          "dialogTitle": "Leave a note for this order",
          "history": {
            "state": {
              "canceled": "The voucher was canceled.",
              "expired": "The voucher has expired.",
              "gift": "The incentive voucher was sent.",
              "issued": "The voucher was issued.",
              "minutes": "{count, plural, =1 {1 minute} other {# minutes}}",
              "ordered": "The voucher was ordered.",
              "paid": "The voucher was paid.",
              "partial_redeemed": "{amount, number, EUR} {amount, plural, =1 {was} other {were}} redeemed {redeemedBy, select, hgv { via HGV booking widget } asa { via ASA HOTEL } other {}}",
              "redeemed": "The voucher was redeemed {redeemedBy, select, hgv { via HGV booking widget } asa { via ASA HOTEL } other {}}"
            },
            "transaction": {
              "bankTransfer": "The voucher will be paid via bank transfer.",
              "canceled": "Transaction canceled on {date} at {time} ",
              "false": "Payment by {paymentType} failed",
              "reactivated": "{user} reactivated the order on {date}, at {time}.",
              "true": "Payment by {paymentType} was successful"
            }
          },
          "noNote": "No note was left for this order.",
          "note": "Note",
          "orderedAt": "Ordered on"
        },
        "expirationDialog": {
          "action": "Extend validity",
          "label": "Expiry date",
          "paymentNotSupported": "The specified payment method is not supported.",
          "placeholder": "Select a payment method",
          "title": "Extend validity"
        },
        "issuedDialog": {
          "action": "Create voucher",
          "message": "This voucher has not been paid yet.<br>Are you sure you want to create the voucher without prior payment?",
          "title": "Warning!"
        },
        "layouts": {
          "landscape": "Landscape Format",
          "portrait": "Portrait Format"
        },
        "paymentDialog": {
          "action": "Mark as paid",
          "label": "Payment method",
          "paymentNotSupported": "This payment method is not supported.",
          "placeholder": "Select a payment method",
          "title": "Mark the voucher as paid"
        },
        "reactivateDialog": {
          "action": "Reactivate order",
          "description": "This action reactivates an already redeemed or canceled order.",
          "title": "Reactivate order"
        },
        "redeemDialog": {
          "action": "Redeem now",
          "amount": {
            "label": "Amount *"
          },
          "comment": {
            "label": "Comment",
            "placeholder": "Add a comment to this action."
          },
          "infoMessage": "Please note that the letters are case sensitive.",
          "redeemCode": {
            "label": "Voucher code *",
            "placeholder": "Voucher code"
          },
          "redeemPartially": "Partially redeem",
          "title": "Redeem voucher"
        },
        "state": {
          "all": "All",
          "canceled": "Canceled",
          "expired": "Expired",
          "gift": "Incentive",
          "issued": "Issued",
          "ordered": "Ordered",
          "paid": "Paid",
          "partial_redeemed": "Partially redeemed",
          "redeemed": "Redeemed",
          "title": "Order Status"
        },
        "type": {
          "gift": "Incentive Vouchers",
          "product": "Product Vouchers",
          "service": "Service Vouchers",
          "stay": "Accommodation Vouchers",
          "title": "Voucher Type",
          "treatment": "Treatment Vouchers",
          "value": "Value Vouchers"
        }
      },
      "searchPlaceholder": "Search for order number, name or voucher code...",
      "title": "Orders",
      "voucher": {
        "activationCode": "Activation code",
        "amount": "Voucher value",
        "amountLeft": "Remaining amount",
        "amountToPay": "Purchase amount",
        "expiresAt": "Expires on",
        "free": "Free",
        "language": "Language",
        "paymentType": "Payment method",
        "product": {
          "count": "Number",
          "name": "Product",
          "price": "Price"
        },
        "redeemCode": "Voucher code",
        "room": {
          "amount": "Room rate",
          "name": "Room",
          "nights": "Number of nights",
          "persons": "People"
        },
        "treatment": {
          "count": "Number",
          "duration": "Duration",
          "minutes": "{count, plural, =1 {1 minute} other {# minutes}}",
          "name": "Treatment",
          "price": "Price"
        },
        "type": "Voucher type",
        "validity": "Validity"
      }
    },
    "pageNotFound": {
      "button": "Homepage",
      "content": "The page you were looking for could not be found!<br>The URL may be incorrect or the page may have been removed or renamed.",
      "title": "Page not found!"
    },
    "routes": {
      "auditLog": {
        "title": "Activity Log"
      },
      "dashboard": {
        "title": "Dashboard"
      },
      "help": {
        "title": "Help and support"
      },
      "orders": {
        "title": "Orders"
      },
      "settings": {
        "general": "General",
        "legal": "Legal",
        "paymentTypes": "Payment Methods",
        "shippingPackage": "Gift & Shipping Options",
        "texts": "Texts",
        "title": "Settings"
      },
      "vouchers": {
        "categories": {
          "title": "Categories"
        },
        "title": "Vouchers"
      },
      "widgets": {
        "navTitle": "Widgets & Templates",
        "templates": {
          "title": "Voucher Templates"
        },
        "title": "Widgets"
      }
    },
    "select": {
      "empty": "No option found",
      "emptyOption": "No selection",
      "noResults": "No results found",
      "searchPlaceholder": "Search"
    },
    "selectContainer": {
      "items": "{count, plural, =1 {# element} other {# elements}} selected"
    },
    "settings": {
      "general": {
        "address": {
          "input": {
            "label": "Address",
            "message": "The address will be printed at the bottom of the voucher."
          },
          "label": "Address",
          "modalTitle": "Address for the voucher"
        },
        "contactInfo": "Contact Details",
        "email": {
          "input": {
            "label": "Email Address",
            "message": "This email address will receive all confirmation emails"
          },
          "label": "Email Address",
          "modalTitle": "Email Address"
        },
        "inheritanceDescription": "If this symbol is enabled (blue), data or content will be synchronized with that of your organization (account). This allows you to manage your data centrally in your organization for all apps.",
        "integration": {
          "asa": {
            "disable": {
              "action": "Disable synchronization",
              "description": "Warning!</br>If you continue, the synchronization between ASA and ADDITIVE+ VOUCHERS will be disabled.</br>The data synchronization can be re-established any time.",
              "title": "Disable ASA synchronization"
            },
            "enable": {
              "action": "Set up synchronization",
              "description": "After successful setup, your voucher orders will be synchronized between ASA and ADDITIVE+ VOUCHERS.",
              "title": "Synchronize with ASA"
            },
            "error": {
              "description": "To proceed with the setup, a valid email address for the organization must be provided.",
              "title": "Synchronize with ASA"
            },
            "excludeDiscounted": "Do not send discounted vouchers",
            "excludeIncentive": "Do not send incentive vouchers",
            "infoDialog": {
              "description": "Thanks to the real-time data synchronization between ADDITIVE+ VOUCHERS and ASA HOTEL, vouchers that are redeemed or paid through the hotel software will be transferred directly to the app and vice versa.</br></br>Additionally, vouchers can be redeemed or partially redeemed with or without reservation and the monetary amount can be transferred onto bills.",
              "title": "What is the ASA synchronization for?"
            },
            "title": "ASA Synchronization",
            "upgradeForFeature": "Upgrade your plan to enable \"ASA synchronization\""
          },
          "hgv": {
            "disable": {
              "action": "Disable",
              "description": "Warning!</br>If you continue, the synchronization between HGV booking widget and ADDITIVE+ VOUCHERS will be disabled.</br>The data synchronization may be re-established any time.",
              "title": "Disable HGV booking widget integration"
            },
            "enable": {
              "action": "Enable integration",
              "description": "After successful setup, your vouchers can be redeemed in the HGV booking widget.",
              "title": "Activate HGV booking widget integration"
            },
            "error": {
              "description": "To proceed with the setup, a valid email address for the organization must be provided.",
              "title": "Activate HGV booking widget integration"
            },
            "infoDialog": {
              "description": "Thanks to the real-time data synchronization between ADDITIVE+ VOUCHERS and HGV booking widget, vouchers that are redeemed through the HGV booking widget will be transferred directly to the app.",
              "title": "What is the HGV booking widget integration?"
            },
            "title": "HGV Booking Widget Integration",
            "upgradeForFeature": "Upgrade your plan to enable \"HGV booking widget integration\"."
          }
        },
        "notifications": {
          "incentive": "For all incentive vouchers generated by various campaigns",
          "notPaid": "Only for unpaid vouchers ordered in the voucher online shop with bank transfer payment",
          "paid": "For all paid vouchers ordered in the voucher online shop",
          "title": "Email notifications"
        },
        "organizationName": {
          "input": {
            "label": "Organization Name"
          },
          "label": "Organization Name",
          "modalTitle": "Organization Name"
        },
        "prefix": {
          "description": "A redeem code prefix consists of one or more characters that will be inserted before the actual redeem code, to make it unique for a certain organization. If you have multiple ADDITIVE+ VOUCHERS licenses it is recommended to define a prefix for each organization you are in. This way you can identify and redeem vouchers faster.",
          "title": "What is a redeem code prefix?"
        },
        "telephone": {
          "input": {
            "label": "Phone Number"
          },
          "label": "Phone Number",
          "modalTitle": "Phone Number"
        },
        "title": "General",
        "vouchers": {
          "prefix": "Voucher code prefix",
          "title": "Vouchers"
        },
        "website": {
          "input": {
            "label": "Website"
          },
          "label": "Website",
          "modalTitle": "Website"
        }
      },
      "legal": {
        "description": "Here you can insert links to the legal texts on your website. These links will be used in the voucher widget.",
        "disclaimerLink": {
          "input": {
            "label": "Link",
            "message": "The link will lead to your disclaimer."
          },
          "label": "Disclaimer",
          "modalTitle": "Disclaimer"
        },
        "privacyLink": {
          "input": {
            "label": "Link",
            "message": "The link will lead to your privacy policy."
          },
          "label": "Privacy Policy",
          "modalTitle": "Privacy Policy"
        },
        "termsLink": {
          "input": {
            "label": "Link",
            "message": "The link will lead to your terms & conditions."
          },
          "label": "Terms of Use",
          "modalTitle": "Terms of Use"
        },
        "title": "Legal"
      },
      "paymentTypes": {
        "add": "Add payment method",
        "bank_transfer": {
          "bankName": "Name of the bank",
          "bic": "BIC",
          "iban": "IBAN"
        },
        "concardis": {
          "apiKey": "API Key",
          "intro": "Enter the following Concardis credentials to enable Concardis as a payment method.",
          "merchantId": "Merchant ID"
        },
        "deleteDialog": {
          "description": "You are about to delete \"{paymentType}\" as a payment method. Are you sure you want to continue?",
          "title": "Warning!"
        },
        "empty": "No payment methods have been configured yet.<br/>Click the button below to add a payment method.",
        "errors": {
          "allAdded": "You cannot add any other payment methods.",
          "countryCurrency": {
            "description": "This payment provider does not support the selected currency in your country.",
            "title": "Warning!"
          },
          "deactivatePaymentType": {
            "description": "At least one other payment method in addition to \"Payment on site\" must remain enabled.",
            "title": "Payment method cannot be disabled"
          },
          "deletePaymentType": {
            "description": "At least one other payment method in addition to \"Payment on site\" must remain enabled.",
            "title": "Payment method cannot be deleted"
          },
          "missingValue": {
            "description": "To enable this payment method all fields must be filled in completely. Please check your entries and try again afterwards.",
            "title": "Warning!"
          },
          "planRestrictions": {
            "plan_allowed_payment_types_exceeded": {
              "description": "In addition to \"PayPal\" and \"bank transfer\", a maximum of one additional payment method may be enabled on your current plan.",
              "title": "Warning!"
            },
            "starter_not_allowed_payment_type": {
              "description": "On your current plan, you may enable either \"bank transfer\" or \"PayPal\" as your payment method.",
              "title": "Warning!"
            }
          }
        },
        "gastro_pool": {
          "intro": "Enter your gastropool key (old version) to enable gastropool (old version) as a payment method.",
          "key": "Key"
        },
        "gastro_pool_v3": {
          "intro": "Enter your gastropool key to enable gastropool as a payment method.",
          "key": "Key"
        },
        "hogast": {
          "intro": "Enter your hogast key (old version) to enable hogast (old version) as payment method.",
          "key": "Key"
        },
        "hogast_v3": {
          "intro": "Enter your hogast key to enable hogast as a payment method.",
          "key": "Key"
        },
        "klarna": {
          "intro": "Enter the following Klarna credentials to enable Klarna as a payment method.",
          "password": "API Password",
          "username": "API Username (UID)"
        },
        "nexi": {
          "apiKey": "API Key",
          "correlationId": "Correlation ID",
          "intro": "Enter the following Nexi credentials to enable Nexi as a payment method."
        },
        "openGuide": "Open guide",
        "oppwa": {
          "activateApplePay": "Enable Apple Pay",
          "activateSofortueberweisung": "Enable instant bank transfer",
          "entityId": "Entity ID",
          "intro": "Enter the following hobex credentials to enable hobex as a payment method.",
          "token": "Access token"
        },
        "paypal": {
          "intro": "Enter the following PayPal credentials to enable PayPal as a payment method.",
          "password": "Password",
          "signature": "Signature",
          "username": "Username"
        },
        "sia_vpos": {
          "apiResultKey": "Chiave Esito API",
          "apiStartKey": "Chiave Avvio",
          "intro": "Enter the following SIA VPOS credentials to enable SIA VPOS as payment method.",
          "shopId": "MID"
        },
        "six_payments": {
          "apiPassword": "API password",
          "apiUser": "API user",
          "customerId": "Customer ID",
          "intro": "Enter the following SIX Payment Services credentials to enable SIX Payment Services as a payment method.",
          "terminalId": "Terminal ID"
        },
        "stripe": {
          "intro": "Enter the following Stripe credentials to enable Stripe as a payment method.",
          "publishableKey": "Publishable Key",
          "secretKey": "Secret Key"
        },
        "title": "Payment Methods",
        "vpos": {
          "id": "ID",
          "intro": "Enter your Virtual-POS ID to enable Virtual-POS as payment method."
        }
      },
      "shipping": {
        "createDialog": {
          "options": {
            "package": "Gift Option",
            "shipping": "Shipping Option"
          },
          "title": "Create new"
        },
        "emptyPackages": "You have not created any gift options yet.<br> You can do so by clicking the \"+\" button.",
        "errors": {
          "accessRights": {
            "message": "You do not have the required permissions to perform this action."
          },
          "defaultEdit": {
            "description": "The shipping option cannot be changed.",
            "title": "Error!"
          },
          "uniqueCountries": {
            "description": "Each country must occur only once per shipping option.",
            "title": "You have used this country more than once!"
          }
        },
        "title": "Shipping Options",
        "upgradeForFeature": "<span class=\"blue\">Upgrade</span> to \"Professional\" to offer gift and shipping options."
      },
      "texts": {
        "customInfo": "Custom Information",
        "emailValediction": {
          "input": {
            "label": "Signature"
          },
          "label": "Signature",
          "modalTitle": "Signature"
        },
        "title": "Texts",
        "voucherGreeting": {
          "input": {
            "label": "Message displayed on voucher",
            "message": "Example text from the giver to the recipient."
          },
          "label": "Predefined Greeting Message",
          "modalTitle": "Predefined Greeting Message"
        },
        "voucherIntro": {
          "input": {
            "label": "Introduction",
            "message": "Introductory text displayed above the category and voucher overview"
          },
          "label": "Introduction"
        },
        "voucherTermsOfUse": {
          "input": {
            "label": "Informative text displayed on the voucher",
            "message": "Informative text which is printed at the bottom of each voucher."
          },
          "label": "Short Terms & Conditions",
          "modalTitle": "Short Terms & Conditions"
        }
      },
      "title": "Settings"
    },
    "style": {
      "loadingMessage": "Is loading..."
    },
    "toast": {
      "bulkDelete": "{count, plural, =1 {one element} other {# elements}} deleted",
      "orderUpdated": {
        "description": "The voucher is being created. This may take a moment.",
        "title": "Changes saved successfully"
      },
      "required": "Value must not be empty",
      "success": "Action was successful",
      "unexpectedError": "An unexpected error occurred",
      "wrongOrderState": {
        "description": "The order could not be reactivated, because it was neither redeemed nor canceled.",
        "title": "Order not redeemed or canceled"
      }
    },
    "toasts": {
      "loading": {
        "description": "Please hold on, we're still loading!",
        "title": "This is taking longer than expected."
      }
    },
    "uiActions": {
      "apply": "Apply",
      "back": "Back",
      "close": "Close",
      "confirm": "Confirm",
      "delete": "Delete",
      "edit": "Edit",
      "export": "Export",
      "info": "Information",
      "menu": "Menu",
      "more": "More",
      "reset": "Reset",
      "save": "Save",
      "saveAndPublish": "Save and publish",
      "search": "Search"
    },
    "uiApplicationNavigationDrawer": {
      "accountSwitch": {
        "empty": "No organization found",
        "organizationSettings": "Organization settings",
        "searchPlaceholder": "Search"
      },
      "appsList": {
        "goToApps": "Go to apps",
        "myApps": "My apps",
        "planNames": {
          "10": {
            "starter_yearly": "Standard"
          },
          "12": {
            "professional_yearly": "Segmentation",
            "starter_yearly": "Customer Data Platform"
          },
          "50": {
            "enterprise_yearly": "MARKETING CLOUD",
            "professional_yearly": "AUTOMATION CLOUD",
            "starter_yearly": "LEAD CLOUD"
          },
          "51": {
            "professional_yearly": "Attribution",
            "starter_yearly": "Tracking"
          },
          "default": {
            "enterprise_monthly": "Enterprise",
            "enterprise_yearly": "Enterprise",
            "professional_monthly": "Professional",
            "professional_yearly": "Professional",
            "standard": "Standard",
            "starter_monthly": "Starter",
            "starter_yearly": "Starter"
          }
        },
        "showMoreApps": "Discover other apps"
      }
    },
    "uiCategoryCard": {
      "contentCountError": "This category cannot be deleted, because it still contains content.",
      "titleError": "Attention!"
    },
    "uiCollapsibleTable": {
      "collapsibleRowDescription": "{count, plural, =1 {# subcategory} other {# subcategories}}"
    },
    "uiColorComposition": {
      "dialog": {
        "preview": "Preview",
        "save": "Save"
      }
    },
    "uiContentCount": {
      "count": "{count, plural, =1 {# entry} other {# entries}}",
      "filterCount": "{count, plural, =1 {# entry} other {# entries}} found",
      "pages": "Page <span class=\"primary ph05\">{page}</span> / <span class=\"pl05\">{total}</span>"
    },
    "uiContentInfo": {
      "showLess": "Show less",
      "showMore": "Show more"
    },
    "uiDateFilter": {
      "label": "Observation period"
    },
    "uiDatePicker": {
      "filters": {
        "custom_date": "Custom date",
        "last_month": "Last month",
        "last_thirty_days": "Last 30 days",
        "last_week": "Last week",
        "this_month": "This month",
        "this_week": "This week"
      },
      "weekDaysShort": {
        "Fri": "Fri",
        "Mon": "Mon",
        "Sat": "Sat",
        "Sun": "Sun",
        "Thu": "Thu",
        "Tue": "Tue",
        "Wed": "Wed"
      }
    },
    "uiDatetimePicker": {
      "date": "Date",
      "endDate": "End date",
      "endTime": "End time",
      "error": {
        "beforeToday": "Past date",
        "false": "Invalid date"
      },
      "startDate": "Start date",
      "startTime": "Start time",
      "time": "Time"
    },
    "uiDiscardChanges": {
      "discardAction": "Discard",
      "message": "Do you want to discard the unsaved changes?",
      "title": "Attention!"
    },
    "uiEditor": {
      "actions": {
        "aiActions": {
          "aiAdjustTone": "Adjust Tone",
          "aiEmojify": "Add Emojis",
          "aiFixSpellingAndGrammar": "Spelling and Grammar",
          "aiRephrase": "Rephrase",
          "aiRestructure": "Format",
          "aiShorten": "Shorten",
          "aiSimplify": "Simplify",
          "aiTextPrompt": "Custom AI Prompt",
          "de": "Translate to German",
          "en": "Translate to English",
          "it": "Translate to Italian"
        },
        "blockquote": "Quote",
        "bold": "Bold",
        "bulletList": "List",
        "code": "Code",
        "codeBlock": "Code",
        "editWithAi": "AI Assistant",
        "headings": {
          "h1": "Heading 1",
          "h2": "Heading 2",
          "h3": "Heading 3",
          "h4": "Heading 4",
          "p": "Normal"
        },
        "headingsShort": {
          "h1": "H1",
          "h2": "H2",
          "h3": "H3",
          "h4": "H4",
          "p": "P"
        },
        "horizontalRule": "Horizontal line",
        "italic": "Italic",
        "link": "Link",
        "numberedList": "Numbered list",
        "orderedList": "Numbered list",
        "placeholder": "Placeholder",
        "quote": "Quote",
        "redo": "Redo",
        "spacer": "Horizontal line",
        "translate": "Translate",
        "underline": "Underline",
        "undo": "Undo",
        "unformat": "Remove formatting"
      },
      "aiDialog": {
        "aiAdjustTone": {
          "description": "Choose the tone you prefer for your text.",
          "inputLabel": "Tone",
          "title": "Adjust tone"
        },
        "aiTextPrompt": {
          "description": "Enter a custom prompt to modify the text.",
          "inputLabel": "Prompt",
          "title": "Custom Prompt"
        },
        "save": "Save"
      },
      "colors": {
        "accent": "Accent color",
        "ambient": "Ambient color",
        "default": "No color",
        "main": "Main color"
      },
      "urlDialog": {
        "openInNewTab": "Open in new tab",
        "save": "Save",
        "text": "Text",
        "type": {
          "name": "Link type",
          "options": {
            "email": "Email",
            "mailto:": "Email",
            "tel": "Phone",
            "tel:": "Phone",
            "weblink": "Web link"
          }
        },
        "url": "URL",
        "urlNotValid": {
          "email": "This email address is not valid",
          "mailto:": "This email address is not valid",
          "tel": "This phone number is not valid",
          "tel:": "This phone number is not valid",
          "weblink": "This URL is not valid"
        }
      }
    },
    "uiFilterInputs": {
      "buttonText": "Filter",
      "daterange": {
        "end": "until",
        "start": "from"
      }
    },
    "uiFormControl": {
      "recommendedLengthWarning": "Value exceeds the recommended length of {recommendedLength} characters"
    },
    "uiFormDialog": {
      "save": "Save"
    },
    "uiFormMessage": {
      "characters": "{charCount} characters"
    },
    "uiListView": {
      "bulkDelete": "{count, plural, =1 {One element} other {# elements}} deleted",
      "common": {
        "bulkDelete": {
          "action": "Delete all",
          "description": "The selected contents will be irrevocably deleted and can no longer be restored.<br/>Are you sure you want to delete the selected contents?"
        },
        "deleteContent": {
          "description": "The content will be irrevocably deleted and can no longer be restored.<br/>Are you sure you want to delete <span class=\"font-medium\">\"{name}\"</span>?",
          "title": "Attention!"
        }
      },
      "conflict_errors": {
        "person": {
          "description": "This person can not be deleted, because it is used by other contents. Make sure to resolve the remaining dependencies in order to delete this person.",
          "title": "This person is in use"
        }
      },
      "delete": "Delete",
      "empty": "There are no existing contents.<br/>Click the button below to create a new content.",
      "error": "An unexpected error occurred.",
      "errors": {
        "rate_in_use": "This rate cannot be deleted, since it is used in a price timespan.",
        "title": "Attention!",
        "used_in_post": "This person cannot be deleted, since it is used in a post.",
        "vouchers_left": "This content cannot be deleted, since it is used in a voucher."
      },
      "noMatch": "Your search did not return any results.",
      "properties": {
        "published": "published",
        "unpublished": "unpublished"
      }
    },
    "uiMonthDayPicker": {
      "day": "Day",
      "month": "Month"
    },
    "uiNavigationBarFilterabel": {
      "dynamicFilters": {
        "buttonText": "More filters",
        "error": {
          "retryButtonText": "An error has occurred! Try again now"
        },
        "navigationDrawer": {
          "title": "More filters"
        }
      },
      "searchInputDefaultPlaceholder": "Search"
    },
    "uiOnboarding": {
      "actions": {
        "back": "Back",
        "endOnboarding": "Get started now",
        "next": "Next",
        "skip": "Skip"
      }
    },
    "uiPublishResource": {
      "description": "Choose which language you would like to publish. You can also limit the time frame for the publication.",
      "descriptionNoLang": "Select the time to publish the content.",
      "errors": {
        "after": "Must be after start date",
        "before": "Must be before end date",
        "required": "Required"
      },
      "languages": "Languages",
      "limitTime": "Limit the time frame",
      "save": "Save",
      "settings": "Settings",
      "title": "Publish"
    },
    "uiRangeSlider": {
      "errorMessage": "The value must be a positive integer",
      "max": "max",
      "min": "min"
    },
    "uiTable": {
      "empty": "No data available",
      "error": "An error has occurred",
      "retry": "Try again",
      "showLess": "Show less",
      "showMore": "Show more"
    },
    "uiTags": {
      "error": "This value has already been added",
      "interests": {
        "interests": "Interests",
        "travelMotivation": "Travel motives",
        "travelTime": "Seasons"
      },
      "placeholder": "Add tag",
      "tags": "Tags"
    },
    "uiTimepicker": {
      "errorMessage": "Invalid time value",
      "timeUnit": ""
    },
    "uiToast": {
      "success": "Action completed successfully",
      "unexpectedError": "An unexpected error has occurred"
    },
    "vouchers": {
      "category": {
        "deleteCategory": {
          "description": "The category will be irrevocably deleted and can no longer be restored.<br/>Are you sure you want to delete <span class=\"font-medium\">\"{name}\"</span>?",
          "title": "Warning!"
        },
        "newCategory": "New category",
        "properties": {
          "description": "Description",
          "image": "Image",
          "name": "Name"
        },
        "voucherCount": "{count, plural, =1 {1 voucher} other {# vouchers}}",
        "voucherCountError": "This category cannot be deleted as there are still vouchers assigned to it."
      },
      "createDialog": {
        "productDescription": "Select the products you would like to offer as vouchers.",
        "serviceDescription": "Define all included services and a fixed price for the voucher.",
        "stayDescription": "Select a room in order to offer accommodation vouchers.",
        "title": "Create a new voucher",
        "treatmentDescription": "Select the treatments you would like to offer as vouchers.",
        "valueDescription": "Buyers can select the voucher value on their own."
      },
      "description": "Create and publish various vouchers. To do this, select the appropriate template from the different types of vouchers. Using the categories allows you to maintain a better overview. In-house vouchers are saved directly in the Orders menu item.",
      "empty": "There are no vouchers yet.<br/>Click on the button below to create one.",
      "expirationFormats": {
        "custom": "Custom",
        "end_of_next_year": "End of next year",
        "end_of_year": "End of year",
        "five_years": "5 years",
        "indefinitely": "Unlimited",
        "one_year": "1 year",
        "three_years": "3 years",
        "three_years_end_of_year": "End of the third year",
        "two_years": "2 years"
      },
      "noMatch": "Your search did not return any results.",
      "searchPlaceholder": "Enter a search term, e.g. service voucher...",
      "title": "Vouchers",
      "types": {
        "dynamic": "Custom",
        "gift": "Incentive Voucher",
        "product": "Product Voucher",
        "service": "Service Voucher",
        "stay": "Accommodation Voucher",
        "treatment": "Treatment Voucher",
        "value": "Value Voucher"
      },
      "voucher": {
        "bulkDeleteVouchers": {
          "action": "Delete all",
          "description": "The selected vouchers will be irrevocably deleted and can no longer be restored.<br/>Are you sure you want to delete them?",
          "title": "Warning!"
        },
        "deleteVoucher": {
          "description": "The voucher will be irrevocably deleted and can no longer be restored.<br/>Are you sure you want to delete <span class=\"font-medium\">\"{name}\"</span>?",
          "title": "Warning!"
        },
        "discount": "{amount, number, EUR} <span class='red'>&nbsp;-{percentage}%<span>",
        "free": "Free",
        "minStays": "{count, plural, =0 {not specified} other {#}}",
        "multimedia": {
          "imageWarning": "Only images with a minimum width of 800px can be selected."
        },
        "numberOfProducts": "{count, plural, =0 {no products selected} =1 {one product selected} other {# products selected}}",
        "numberOfTreatments": "{count, plural, =0 {no treatments selected} =1 {one treatment selected} other {# treatments selected}}",
        "personCount": "{count, plural, =0 {not specified} other {#}}",
        "product": {
          "columns": {
            "name": "Product",
            "price": "Price"
          },
          "editProducts": "Edit products",
          "empty": "No published products have been selected for this voucher yet.",
          "invalidProductsWarning": "Even though this voucher is published it cannot be used as there are no products with valid price in the selected categories. Please create a product with valid price for this voucher.",
          "products": "Products",
          "showProduct": "Show product"
        },
        "productCategoryCount": "{count, plural, =0 {no product categories selected} =1 {one product category selected} other {# product categories selected}}",
        "properties": {
          "amount": "Price",
          "category": "Category",
          "description": "Description",
          "discountPeriodEnd": "End",
          "discountPeriodStart": "Start",
          "discountedAmount": "Discount Price",
          "discountedPrice": "Discount Price",
          "errors": {
            "not_exists": {
              "description": "The necessary translations are missing for one or more treatments. Please check if the treatments have been translated in all available languages of the voucher.",
              "title": "Translations missing"
            },
            "roomTypeTranslationsMissing": {
              "buttonText": "Check room",
              "description": "The required translations are missing for one or more room types. Please check if the room types have been translated in all available languages of the voucher.",
              "title": "Translations missing"
            }
          },
          "hasDiscountPeriod": "Limited-time Discount",
          "images": "Images",
          "infoMessages": {
            "description": "Will not be printed on the voucher",
            "name": "The title will be printed on the voucher. For reasons of comprehension we recommend including the word \"voucher\" in the title."
          },
          "interestTags": {
            "info": "Add suitable keywords to provide your guests and potential guests with the exact content that matches their preferences.",
            "placeholder": "Add interests, travel motives or favorite travel periods",
            "title": "Interests / Travel Motives / Favorite Travel Periods"
          },
          "isDiscounted": "Offer a discount",
          "isLocked": "Use an activation code",
          "language": "Language",
          "maxAmount": "Maximum Value",
          "minAmount": "Minimum Value",
          "minStays": "Minimum Number of Overnight Stays",
          "name": "Title",
          "personCount": "Number of People",
          "price": "Price",
          "productCategories": "Product Categories",
          "published": "Published",
          "roomTypes": "Room Types",
          "services": "Services",
          "stays": "Overnight Stays",
          "treatmentCategories": "Treatment Categories",
          "type": "Voucher Type",
          "unlockCode": "Activation Code",
          "unpublished": "Unpublished",
          "validityPeriod": "Validity",
          "voucherValue": "Voucher Value",
          "warnings": {
            "services": "This text exceeds the number of recommended characters. Remember to check the voucher before you publish it."
          }
        },
        "publicRooms": "Only rooms that have been published and have a base price can be selected.",
        "publish": {
          "date": "The voucher will be public from {from} to {to}.",
          "description": "Publish voucher",
          "info": "Please add an image to publish the voucher."
        },
        "rooms": "{count, plural, =0 {no rooms selected} other {# rooms}}",
        "sections": {
          "discount": {
            "freeHelpText": "This voucher can be ordered for free. If you do not wish for the voucher to be redeemed several times, please write a description that makes clear the services can be redeemed only once.",
            "label": "Discount",
            "options": {
              "discounted": "Discount",
              "free": "Free / As incentive",
              "noDiscount": "No discount"
            },
            "title": "Discount / Incentive"
          },
          "general": "General",
          "unlockCode": {
            "description": "If you create an activation code, the voucher will not be displayed on the website. Only users, who enter the code in the respective field, will be able to see it.",
            "generate": "Create an activation code",
            "infoMessage": "Avoid similar looking characters, such as O and 0 or l and 1.",
            "label": "Please note that the activation code must be unique.",
            "title": "Activation Code"
          }
        },
        "stay": {
          "columns": {
            "basePrice": "Base price",
            "name": "Room",
            "occupancy": "Room occupancy",
            "priceInformation": "Price information"
          },
          "editRoomTypes": "Edit rooms",
          "empty": "No published rooms have been selected for this voucher yet.",
          "invalidRoomTypesWarning": "Even though this voucher is published it cannot be used as there are no rooms with valid price in the selected categories. Please create a room with valid price for this voucher.",
          "per_person": "Per person",
          "per_room": "Per accommodation unit",
          "showRoomType": "Show room",
          "v1WidgetWarning": "You are currently using an older version of the widget which does not support the feature \"price information (per person/accommodation unit)\". Ask your website provider to embed the new version of the widget on your website to enable this feature. The corresponding codes can be found in the menu item \"widgets & templates\"."
        },
        "stays": "{count, plural, =0 {no overnight stays} =1 {# overnight stay} other {# overnight stays}}",
        "treatment": {
          "columns": {
            "duration": "Duration",
            "name": "Treatment",
            "price": "Price"
          },
          "editTreatments": "Edit treatments",
          "empty": "No published treatments have been selected for this voucher yet.",
          "invalidTreatmentsWarning": "Even though this voucher is published it cannot be used as there are no treatments with valid price in the selected categories. Please create a treatment with valid price for this voucher.",
          "minutes": "{count} min.",
          "showTreatment": "Show treatment",
          "treatments": "Treatments"
        },
        "treatmentCategoryCount": "{count, plural, =0 {no treatment categories selected} =1 {one treatment category selected} other {# treatment categories selected}}"
      }
    },
    "widget": {
      "errorMessage": "An error occurred while loading the widget",
      "helpTexts": {
        "inheritanceDescription": "When this icon is enabled (blue), the data is synchronized with that of your organization (account). This allows you to manage the data centrally in your organization for all apps."
      },
      "loadingMessage": "The widget is being initialized.",
      "retry": "Retry"
    },
    "widgets": {
      "configurator": {
        "colors": {
          "accent": "Accent color",
          "ambient": "Ambient color",
          "error": "Error message",
          "main": "Main color",
          "stateColors": "Other color settings",
          "title": "Colors",
          "titleFailure": "Title in case of incorrect order",
          "titleSuccess": "Title in case of successful order"
        },
        "general": {
          "borderRadius": "Rounded corners",
          "boxShadow": "Box shadow",
          "categoryDescription": "Category description",
          "layout": "Layout",
          "overlay": "Voucher preview",
          "ownContent": "Own content",
          "placeholderContent": "Placeholder content",
          "scrollOffset": "Scroll behavior",
          "scrollOffsetDesktop": "Scroll offset desktop",
          "scrollOffsetMobile": "Scroll offset mobile",
          "scrollOnInitToggle": "Scroll on initialization",
          "settings": "Widget configuration",
          "template": "Voucher template",
          "title": "General",
          "toggleAdvanced": "{boolean, select, true {Hide} other {Show}} further options",
          "tooltip": "Tooltips",
          "zIndex": "z-index (CSS property)"
        },
        "helpTexts": {
          "colors": {
            "accent": "Accent color for buttons and interactive elements.",
            "ambient": "Background color of the environment in which the widget is embedded.",
            "error": "Color of error messages.",
            "main": "Primary font color for the whole widget.",
            "titleFailure": "Color of the title that is displayed after an incorrect order.",
            "titleSuccess": "Color of the title that is displayed after a successful order."
          },
          "general": {
            "borderRadius": "Radius of the rounded corners in the widget (CSS property).",
            "boxShadow": "Appearance of the box shadows in the widget (CSS property).",
            "categoryDescription": "Should the category view be displayed with a description text or a background image?",
            "overlay": "z-index (CSS property) for the voucher preview.",
            "scrollOffsetDesktop": "Scroll offset that is used for scroll operations in the widget. For container width >= 800px.",
            "scrollOffsetMobile": "Scroll offset that is used for scroll operations in the widget. For container width < 800px.",
            "scrollOnInitToggle": "Do you want to activate an automatic scroll to the voucher preview when the page is loaded?",
            "template": "Voucher template that is used for this widget.",
            "templateDisabled": "The voucher template can only be edited if the option \"own content\" is enabled.",
            "tooltip": "z-index (CSS property) that is used for every tooltip in the widget."
          },
          "tracking": {
            "description": "If you process tracking events via \"data layer\" using the Google Tag Manager, you can disable individual tracking settings. The values ​​are provided in the custom event \"AdditiveApps\"."
          },
          "typography": {
            "primary": "Title font",
            "secondary": "Running text font ",
            "warning": "Custom font families will not appear in the preview."
          }
        },
        "template": {
          "helpTexts": {
            "colors": {
              "accent": "Accent color for protruding elements.",
              "ambient": "Background color for the template",
              "main": "Primary font color for the whole template."
            }
          },
          "typography": {
            "properties": {
              "h1": {
                "description": "",
                "title": "Heading"
              },
              "label": {
                "description": "",
                "title": "Included Services and Footer"
              },
              "p": {
                "description": "",
                "title": "Message"
              },
              "small": {
                "description": "",
                "title": "Included Services"
              }
            }
          }
        },
        "title": "Widget Configuration",
        "tracking": {
          "facebook": "Facebook Pixel",
          "google": "Google Analytics",
          "openGuide": "Open tracking guide",
          "title": "Tracking"
        },
        "typography": {
          "defaultFont": "Default",
          "fontColor": "Font color",
          "fontFamilies": "Font families",
          "fontFamily": "Font family",
          "fontOptions": {
            "default": "Standard",
            "sansserif": "Sans-serif",
            "serif": "Serif"
          },
          "fontSize": "Font size",
          "fonts": "Fonts",
          "lineHeight": "Line height",
          "primary": "Primary",
          "properties": {
            "button": {
              "description": "",
              "title": "Buttons"
            },
            "h2": {
              "description": "",
              "title": "Headings"
            },
            "h3": {
              "description": "",
              "title": "Subheadings"
            },
            "label": {
              "description": "",
              "title": "Labels"
            },
            "p": {
              "description": "",
              "title": "Text"
            },
            "small": {
              "description": "",
              "title": "Text (small)"
            }
          },
          "secondary": "Secondary",
          "settings": {
            "embedFonts": {
              "description": "When this option is enabled, all used fonts will be embedded in the widget. This can lead to longer loading times.",
              "title": "Embed fonts"
            },
            "fontName": {
              "dialog": {
                "bold": "Bold",
                "confirm": "Confirm",
                "description": "The names of the various fonts can be defined below. Please be aware that the selected name must correspond with the name used on the website. Should the font width be defined by the CSS property \"font-weight\" rather than by individual names, you may use the same name for each font style.",
                "light": "Light",
                "normal": "Regular",
                "title": "Font name for \"{font}\""
              },
              "title": "Font Name"
            },
            "title": "Settings"
          },
          "title": "Typography",
          "types": {
            "customFonts": "Custom fonts",
            "sansserif": "Sans-serif fonts",
            "serif": "Serif fonts"
          }
        }
      },
      "create": {
        "title": "Create a new widget"
      },
      "deleteDialog": {
        "message": "Are you sure you want to delete the widget?<br>This action cannot be undone. In case the widget is currently embedded on a website it will no longer work.",
        "title": "Delete widget"
      },
      "description": "Define the styles for widgets and voucher templates. Afterwards, you can generate the HTML code to embed the widgets on your website.",
      "edit": {
        "title": "Edit widget"
      },
      "planUpgrade": {
        "message": "You have reached the maximum number of widgets available for your plan.<br/>Please upgrade your plan if you want to create more widgets."
      },
      "template": {
        "actions": {
          "setAsDefault": "Set as default"
        },
        "edit": {
          "title": "Voucher Template Configuration"
        },
        "isDefault": "Default",
        "layouts": {
          "landscape": "Landscape Format",
          "portrait": "Portrait Format"
        },
        "types": {
          "custom-slant": "{orgName}",
          "custom-square": "{orgName}",
          "simple": "Simple",
          "slant": "Slant",
          "square": "Square"
        },
        "typography": {
          "h1": {
            "description": "",
            "title": "Heading"
          },
          "h2": {
            "description": "",
            "title": "Heading"
          },
          "label": {
            "description": "",
            "title": "Included Services & Footer"
          },
          "p": {
            "description": "",
            "title": "Message"
          },
          "small": {
            "description": "",
            "title": "Included Services"
          }
        }
      },
      "title": "Widgets",
      "v1": {
        "badge": "Configuration not possible",
        "message": "You are using an older version of the widget which does not support this type of advanced configuration. ",
        "title": "You are using an older version of the widget"
      },
      "widget": {
        "properties": {
          "description": "Description",
          "name": "Name"
        }
      }
    }
  }]];
});