define("ember-svg-jar/inlined/remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.667 10a6.667 6.667 0 11-13.334 0 6.667 6.667 0 0113.334 0zm-1.25 0a5.417 5.417 0 11-10.834 0 5.417 5.417 0 0110.834 0z\"/><path d=\"M13.536 10.208c0 .23-.187.417-.417.417H6.88a.417.417 0 01-.417-.417v-.416c0-.23.187-.417.417-.417h6.238c.23 0 .417.186.417.417v.416z\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});